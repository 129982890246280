/* eslint-disable guard-for-in */
import 'ng-redux';
import { isMobile } from 'mobile-device-detect';

import { RecordStateConfig, ReportStateConfig } from '../power-state-config/power-state-config';

class PowerNavigationMenuController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$rootScope',
      '$scope',
      '$http',
      'urlApi',
      'menuConfigurationServices',
      'recordServices',
      'version',
      '_applications',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $rootScope,
    $scope,
    $http,
    urlApi,
    menuConfigurationServices,
    recordServices,
    version,
    _applications,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $rootScope,
      $scope,
      $http,
      urlApi,
      menuConfigurationServices,
      recordServices,
      version,
      _applications,
    });

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Reducer */
        isAdm: behavior.session.isAdm,
        userName: behavior.session.userName,
        hasUpdate: behavior.session.hasUpdate,
        application: behavior.session.application,
        companyName: behavior.session.companyName,
        isConnected: !!behavior.session.token,
        isSingleSignon: behavior.session.isSingleSignon,
        navigationList: behavior.session.navigationList,
        isVideoTelemetry: behavior.session.isVideoTelemetry,
        adminNavigationList: behavior.session.adminNavigationList,
        onAdmNavigationMode: behavior.session.onAdmNavigationMode,
        headerIconAllowColorChange: behavior.session.headerIconAllowColorChange,

        applicationName: behavior.session.applicationName,
        applicationNameVisibility: behavior.session.applicationNameVisibility,
        /* State Reducer */
        routeList: behavior.state.routeList,
        currentState: behavior.state.routeList[behavior.state.routeList.length - 1],
      }),
    )(this);

    this.menuNavigationList = [];
    this.navigationSearchText = '';

    this.navigationMenuSearchInputFocusEvent = this._onNavigationMenuSearchInputFocus.bind(this);
    this.navigationMenuSearchInputBlurEvent = this._onNavigationMenuSearchInputBlur.bind(this);

    // Atualizar o menu a cada 10 minutos
    this.intervalRealTimeDefault = 600000;
    this._refreshMenuRealTime();

    this.isMobile = isMobile;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      toggleSubRoute: this.toggleSubRoute.bind(this),
      loadMenuNavigationList: this.loadMenuNavigationList.bind(this),
      refreshMenuNavigationList: this.refreshMenuNavigationList.bind(this),
    });

    this.$.querySelector('#navigation-menu-search-input').addEventListener(
      'focus',
      this.navigationMenuSearchInputFocusEvent,
    );
    this.$.querySelector('#navigation-menu-search-input').addEventListener(
      'blur',
      this.navigationMenuSearchInputBlurEvent,
    );

    this.$scope.$watch(
      () => this.onAdmNavigationMode,
      this.__onAdmNavigationModeChanged.bind(this),
    );
    this.$scope.$watch(
      () => this.navigationSearchText,
      this.__onNavigationSearchTextChanged.bind(this),
    );
  }

  $doCheck() {
    requestAnimationFrame(() => this.__routeChanged());
  }

  $onDestroy() {
    this.__appBehavior();
    this.$.querySelector('#navigation-menu-search-input').removeEventListener(
      'focus',
      this.navigationMenuSearchInputFocusEvent,
    );
    this.$.querySelector('#navigation-menu-search-input').removeEventListener(
      'blur',
      this.navigationMenuSearchInputBlurEvent,
    );
  }
  /* */

  /* Public */
  toggle() {
    if (!this.$.hasAttribute('open')) {
      this.$.setAttribute('open', '');
    } else {
      this.$.removeAttribute('open');
      if (
        this.currentState &&
        this.currentState.stateConfig &&
        this.currentState.stateConfig.isAdm != this.onAdmNavigationMode
      ) {
        this._toggleAdmin();
      }
      this.menuNavigationList.map(route =>
        this.$.querySelector(`[data-id="${route.id}"]`).removeAttribute('open'),
      );
      this.navigationSearchText = '';
    }
  }

  toggleSubRoute(routeId, open) {
    const routeButton = this.$.querySelector(`[data-id="${routeId}"]`);

    if (!open && routeButton.hasAttribute('open')) {
      routeButton.removeAttribute('open');
    } else {
      routeButton.setAttribute('open', '');
    }
  }

  loadMenuNavigationList() {
    if (this.onAdmNavigationMode)
      this.menuNavigationList = this.adminNavigationList.map(ele => ({ ...ele }));
    else this.menuNavigationList = this.navigationList.map(ele => ({ ...ele }));
  }

  async refreshMenuNavigationList() {
    return this.menuConfigurationServices.getMenuConfiguration().then(response => {
      if (response.status === 200 && response.data) {
        const { data } = response.data;
        const {
          adminNavigationList,
          navigationList,
          userReportQuantity,
          userReportSharedQuantity,
          userAutoSendQuantity,
        } = data || {};

        if (!adminNavigationList || !navigationList) return;

        this.$ngRedux.dispatch({
          type: 'UPDATE_ROUTE',
        });
        this.$ngRedux.dispatch({
          type: 'UPDATE_NAVIGATION_MENU',
          data: { adminNavigationList, navigationList },
        });

        this.$ngRedux.dispatch({
          type: 'UPDATE_USER_REPORT_QUANTITY',
          data: { userReportQuantity },
        });

        this.$ngRedux.dispatch({
          type: 'UPDATE_USER_REPORT_SHARED_QUANTITY',
          data: { userReportSharedQuantity },
        });

        this.$ngRedux.dispatch({
          type: 'UPDATE_USER_AUTO_SEND_QUANTITY',
          data: { userAutoSendQuantity },
        });

        setTimeout(() => {
          this.loadMenuNavigationList();
          this.$rootScope.$apply();
        }, 300);
      }
    });
  }
  /* */

  /* Private */
  _focusInput() {
    if (this.$.hasAttribute('open')) {
      this.$.querySelector('#navigation-menu-search-input').focus();

      return;
    }

    this.$.setAttribute('open', '');

    const afterAnimation = () => {
      this.$.querySelector('#navigation-menu-search-input').focus();

      this.$.querySelector('aside').removeEventListener('transitionend', afterAnimation);
    };

    this.$.querySelector('aside').addEventListener('transitionend', afterAnimation);
  }

  _toggleAdmin() {
    this.$ngRedux.dispatch({ type: 'TOGGLE_NAVIGATION_MODE' });
  }

  _newRoute(route) {
    if (!route.route && !route.showInfo) {
      if (this.$.hasAttribute('open') === false) {
        this.$.setAttribute('open', '');
      }

      this.toggleSubRoute(route.id);
    } else if (!route.identificator || route.identificator == 5) {
      this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
      this.$state.go(
        route.showInfo ? route.routeInfoAddon : route.route,
        {
          // Addon Services
          routeId: route.id,
          serviceId: route.serviceId,
          serviceName: route.description,
          serviceIcon: route.icon,

          // Custom Reports
          isCustomReport: route.isCustomReport,
          customReportId:
            route.isCustomReport && route.idCustomReport ? route.idCustomReport : route.id,
        },
        { reload: true },
      );
    } else if (route.type == 1 || route.type == 2 || route.type == 3 || route.type == 6) {
      const method = `${route.route == 'UserAdm' ? 'User' : route.route}/Get${
        route.route.split('Adm')[0]
      }Information`;
      const params = { id: route.id };
      const stateConfig = new RecordStateConfig({
        isAdm: this.isAdm,
        getDataMethod: method,
        getDataFixedParams: params,
      });

      this.recordServices.getRecordConfig(route.route).then(result => {
        // eslint-disable-next-line no-restricted-syntax
        for (const attr in result) {
          stateConfig[attr] = result[attr];
        }
        let routeLink = 'record';
        let recordRouteSubName;

        switch (route.route) {
          case 'UserAdm':
            recordRouteSubName = 'Detalhes do Usuário';
            this.$ngRedux.dispatch({
              type: 'NEW_ROUTE',
              data: {
                routeName: 'Usuários',
                routeLink: 'users',
                routeTail: null,
                stateConfig: new ReportStateConfig({
                  type: 'User',
                  isAdm: true,
                  viewMode: 'grid',
                  gridName: 'List',
                  screenName: 'User',
                  toolbarName: 'List',
                  getDataMethod: 'User/Post',
                  isCustomReport: route.isCustomReport,
                  customReportId: route.id,
                  backPagination: false,
                  getDataFilters: [],
                  getDataFixedParams: {},
                }),
              },
            });
            break;
          case 'GroupAdm':
            recordRouteSubName = 'Detalhes do Grupo Admin';
            this.$ngRedux.dispatch({
              type: 'NEW_ROUTE',
              data: {
                routeName: 'Grupos',
                routeSubName: 'Grupos Adm',
                routeLink: 'groupsAdm',
                routeTail: null,
                stateConfig: new ReportStateConfig({
                  type: 'Group',
                  isAdm: true,
                  viewMode: 'grid',
                  gridName: 'List',
                  screenName: 'GroupAdm',
                  toolbarName: 'List',
                  getDataMethod: 'GroupAdm/Post',
                  isCustomReport: route.isCustomReport,
                  customReportId: route.id,
                  backPagination: false,
                  getDataFilters: [],
                  getDataFixedParams: {},
                }),
              },
            });
            break;
          case 'Group':
            recordRouteSubName = 'Detalhes do Grupo';
            this.$ngRedux.dispatch({
              type: 'NEW_ROUTE',
              data: {
                routeName: 'Grupos',
                routeLink: 'groups',
                routeTail: null,
                stateConfig: new ReportStateConfig({
                  type: 'Group',
                  isAdm: false,
                  viewMode: 'grid',
                  gridName: 'List',
                  screenName: 'Group',
                  toolbarName: 'List',
                  getDataMethod: 'Group/Post',
                  isCustomReport: route.isCustomReport,
                  customReportId: route.id,
                  backPagination: false,
                  getDataFilters: [],
                  getDataFixedParams: {},
                }),
              },
            });
            break;
          case 'VehicleAdm':
            routeLink = 'recordVehicle';
            recordRouteSubName = 'Detalhes do Veículo Admin';
            this.$ngRedux.dispatch({
              type: 'NEW_ROUTE',
              data: {
                routeName: 'Veículos',
                routeLink: 'vehiclesAdm',
                routeSubName: 'Veículos Adm',
                routeTail: null,
                stateConfig: new ReportStateConfig({
                  type: 'Vehicle',
                  isAdm: true,
                  viewMode: 'grid',
                  gridName: 'List',
                  screenName: 'VehicleAdm',
                  toolbarName: 'List',
                  getDataMethod: `${this.isAdm ? 'VehicleAdm' : 'Vehicle'}/Post`,
                  isCustomReport: route.isCustomReport,
                  customReportId: route.id,
                  backPagination: false,
                  getDataFilters: [],
                  getDataFixedParams: {},
                }),
              },
            });
            break;
          case 'Vehicle':
            routeLink = 'recordVehicle';
            recordRouteSubName = 'Detalhes do Veículo';
            this.$ngRedux.dispatch({
              type: 'NEW_ROUTE',
              data: {
                routeName: 'Veículos',
                routeLink: 'vehicles',
                routeTail: null,
                stateConfig: new ReportStateConfig({
                  type: 'Vehicle',
                  isAdm: false,
                  viewMode: 'grid',
                  gridName: 'List',
                  screenName: 'Vehicle',
                  toolbarName: 'List',
                  getDataMethod: `${this.isAdm ? 'VehicleAdm' : 'Vehicle'}/Post`,
                  isCustomReport: route.isCustomReport,
                  customReportId: route.id,
                  backPagination: false,
                  getDataFilters: [],
                  getDataFixedParams: {},
                }),
              },
            });
            break;
          case 'VehicleSmart':
            routeLink = 'recordVehicleSmart';
            recordRouteSubName = 'Detalhes do Veículo';
            this.$ngRedux.dispatch({
              type: 'NEW_ROUTE',
              data: {
                routeName: 'Veículos',
                routeLink: 'recordVehicleSmart',
                routeTail: null,
                stateConfig: new ReportStateConfig({
                  type: 'Vehicle',
                  isAdm: false,
                  viewMode: 'grid',
                  gridName: 'List',
                  screenName: 'Vehicle',
                  toolbarName: 'List',
                  getDataMethod: `${this.isAdm ? 'VehicleAdm' : 'Vehicle'}/Post`,
                  isCustomReport: route.isCustomReport,
                  customReportId: route.id,
                  backPagination: false,
                  getDataFilters: [],
                  getDataFixedParams: {},
                }),
              },
            });
            break;
          default:
            return;
        }
        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: route.description,
            routeLink,
            routeSubName: recordRouteSubName,
            routeTail: route.id,
            stateConfig,
          },
        });
        this.$state.go(routeLink, { tail: route.id });
      });
    } else if (route.type == 4) {
      this.$state.go(route.route);
    }
  }

  _toggleVersionPopup() {
    document.querySelector('#popup-app-info').toggle();
  }

  _toggleDownloadPopup() {
    document.querySelector('#popup-download-info').toggle();
  }

  _refreshMenuRealTime() {
    setInterval(() => {
      const popupAppInfo = document.querySelector('#popup-app-info');
      if (
        this.isConnected &&
        !this.$.hasAttribute('open') &&
        (!popupAppInfo || !popupAppInfo.hasAttribute('open'))
      ) {
        this.refreshMenuNavigationList();
      }
    }, this.intervalRealTimeDefault);
  }

  _onNavigationMenuSearchInputFocus() {
    this.$.querySelector('#navigation-menu-search').setAttribute('active', '');
  }

  _onNavigationMenuSearchInputBlur() {
    if (!this.navigationSearchText) {
      this.$.querySelector('#navigation-menu-search').removeAttribute('active', '');
    }
  }
  /* */

  /* Observers */
  __routeChanged() {
    const [firstRoute] = this.routeList;
    const [lastRoute] = [...this.routeList].reverse();

    const selectRoute = routeId => {
      this.$.querySelectorAll('[selected]').forEach(node => {
        node.removeAttribute('selected');
      });

      this.$.querySelector(`[data-id="${routeId}"]`).setAttribute('selected', '');
    };

    const searchRoute = route => {
      for (const navigationRoute of this.menuNavigationList) {
        const node = this.$.querySelector(`[data-id="${navigationRoute.id}"]`);

        if (!node) return;

        if (
          route &&
          (navigationRoute.route == route.routeLink ||
            (navigationRoute && navigationRoute.id === (route.stateConfig || {}).routeId))
        ) {
          selectRoute(navigationRoute.id);
          break;
        } else if (
          route &&
          navigationRoute.itens?.length > 0 &&
          navigationRoute.itens.filter(subRoute => subRoute.route == route.routeLink).length == 1
        ) {
          selectRoute(navigationRoute.id);
          break;
        }
      }
    };

    searchRoute(firstRoute);

    if (this.routeList > 1) {
      searchRoute(lastRoute);
    }
  }

  __onAdmNavigationModeChanged(newValue) {
    if (newValue) {
      this.$.setAttribute('admin', '');
      this.menuNavigationList = this.adminNavigationList.map(ele => ({ ...ele }));
    } else {
      this.$.removeAttribute('admin');
      this.menuNavigationList = this.navigationList.map(ele => ({ ...ele }));
    }
  }

  __onNavigationSearchTextChanged(newValue) {
    if (newValue.length >= 3)
      this.$http({
        url: `${this.urlApi}/GeneralSearch/Search`,
        method: 'POST',
        data: { search: newValue },
      }).then(result => {
        this.menuNavigationList = result.data.data.map(ele => ({
          ...ele,
          type: ele.identificator,
        }));
      });
    else this.loadMenuNavigationList();
  }
  /* */
}

export { PowerNavigationMenuController };
