/* global H: true isIframe: true */
import { PowerMapVehiclesHereProvider } from '@power/power-components/components/power-map-vehicles/providers/here/power-map-vehicles.here-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-smart-map-vehicles.here-provider.scss';

class GolfleetSmartMapVehiclesHereProvider extends PowerMapVehiclesHereProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        __modules: behavior.session.modules,
      }),
    )(this);

    this.intervalFullscreen = null;
    this.msgInterval = null;
    this.context._nearbyVehicleList = [];

    this.context.pointsOfInterestController = {
      callback: null,
      type: 'point',
      positionList: [],
      // Tap Event
      tapEventListener: evt => {
        if (this.context.pointsOfInterestController.type === 'point')
          this.context.pointsOfInterestController.pointTapHandler(evt);
      },
      pointTapHandler: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};
        const cardGeoVehicle = document.querySelector('.card-geo-vehicle');
        const selectMarker = document.querySelector('#marker-point');

        if (targetDataset?.geoJson?.type == 'Point') {
          if (!cardGeoVehicle.hasAttribute('active')) {
            cardGeoVehicle.setAttribute('active', '');
          }
          this._searchNearBy(targetDataset.latitude, targetDataset.longitude);
        } else if (!selectMarker.hasAttribute('active')) {
          cardGeoVehicle.removeAttribute('active');
          this.context._nearbyVehicleList = [];
        }
      },
    };
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _geoJsonToVehicleMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon: markerIcon || this._createMarkerIcon(geoJson.properties),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.placaComApelido}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${geoJson.properties.ultimaPosicaoFormatado}</span>
              </div>
              <div>
                <b>Velocidade:</b>
                <br>
                <span>
                  <i class="material-icons"
                    style="font-size: 11px;
                    color: ${!geoJson.properties.ignicao ? '#980A1A' : '#4AAE4E'}">
                    lens
                  </i>
                  ${geoJson.properties.velocidadeFormatada}
                </span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px;text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="${`https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${lat} </span>
              <span> Lon: ${lng} </span>
            </div>
          `,
        },
      },
    );
  }
  /* */

  /* Observers */
  /* */
}

export { GolfleetSmartMapVehiclesHereProvider };
