import angular from 'angular';
import 'ng-redux';

import 'nouislider/distribute/nouislider.css';

import '../golfleet-smart-map-positions/golfleet-smart-map-positions';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-grid-positions/power-grid-positions';
import '@power/power-components/components/power-photo-indexation/power-photo-indexation';
import '@power/power-components/components/power-media-events/power-media-events';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-chart-position/power-chart-position';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components//directives/ng-resize/ng-resize';

import { PowerReportPositionsController } from '@power/power-components/components/power-report-positions/power-report-positions';

import template from './golfleet-smart-report-positions.html';
import './golfleet-smart-report-positions.scss';

class GolfleetSmartReportPositionsController extends PowerReportPositionsController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  /* */
}

class GolfleetSmartReportPositions {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetSmartReportPositionsController;
  }
}

angular
  .module('golfleet-smart-report-positions', [
    'ngRedux',
    'ng-tippy',
    'power-dropdown',
    'power-toolbar',
    'power-grid-positions',
    'golfleet-smart-map-positions',
    'power-photo-indexation',
    'power-media-events',
    'power-footer',
    'power-pagination',
    'power-chart-position',
    'power-toast',
    'ng-resize',
  ])
  .component('golfleetSmartReportPositions', new GolfleetSmartReportPositions());

export { GolfleetSmartReportPositionsController };
