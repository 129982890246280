import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { PowerMapPositionsController } from '@power/power-components/components/power-map-positions/power-map-positions';
import { GolfleetSmartMapPositionsHereProvider } from './providers/here/golfleet-smart-map-positions.here-provider';
import { GolfleetSmartMapPositionsLeafletProvider } from './providers/leaflet/golfleet-smart-map-positions.leaflet-provider';

import template from './golfleet-smart-map-positions.html';

class GolfleetSmartMapPositionsController extends PowerMapPositionsController {
  static get $inject() {
    return ['$element', '$ngRedux', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetSmartMapPositionsHereProvider(
        this,
        $element,
        $ngRedux,
        $rootScope,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetSmartMapPositionsLeafletProvider(
        this,
        $element,
        $ngRedux,
        $rootScope,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /**/

  /* Public */
  /**/

  /* Private */
  /**/
}

class GolfleetSmartMapPositions {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetSmartMapPositionsController;
  }
}

angular
  .module('golfleet-smart-map-positions', ['power-map'])
  .component('golfleetSmartMapPositions', new GolfleetSmartMapPositions());

export { GolfleetSmartMapPositionsController };
