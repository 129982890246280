import angular from 'angular';
import '@power/power-components/components/power-popup/power-popup';

import './golfleet-popup-privacy-policy.scss';

import {
  PowerPopupPrivacyPolicy,
  PowerPopupPrivacyPolicyController,
} from '@power/power-components/components/power-popup-privacy-policy/power-popup-privacy-policy';

class GolfleetPopupPrivacyPolicyController extends PowerPopupPrivacyPolicyController {
  constructor($element, $ngRedux, $http, urlApi) {
    super($element, $ngRedux, $http, urlApi);

    this.config = {
      logo: '/assets/images/golfleet-icon-color.svg',
      logoAlt: 'Golfleet Tecnologia',
      headerText: 'Política de Privacidade',
      textInfo:
        'Para continuar fazendo uso da plataforma Golfleet, é necessário que você leia e esteja de acordo com os termos da nossa política de privacidade.',
      textLinkInfo: 'Política de Privacidade Golfleet Versão',
    };
  }
}

class GolfleetPopupPrivacyPolicy extends PowerPopupPrivacyPolicy {
  constructor() {
    super();
    this.controller = GolfleetPopupPrivacyPolicyController;
  }
}

angular
  .module('golfleet-popup-privacy-policy', ['power-popup'])
  .component('golfleetPopupPrivacyPolicy', new GolfleetPopupPrivacyPolicy());
