import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { PowerMapController } from '@power/power-components/components/power-map/power-map';
import { GolfleetMapReverseGeocodeHereProvider } from './providers/here/golfleet-map-reverse-geocode.here-provider';
import { GolfleetMapReverseGeocodeLeafletProvider } from './providers/leaflet/golfleet-map-reverse-geocode.leaflet-provider';

import template from './golfleet-map-reverse-geocode.html';

class GolfleetMapReverseGeocodeController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$rootScope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $rootScope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetMapReverseGeocodeHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $rootScope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetMapReverseGeocodeLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $rootScope,
        $http,
        urlApi,
      );
    }
  }

  /* Lifecycle */

  $onInit() {
    Object.assign(this.$, {
      setMarker: this.setMarker.bind(this),
      resetMap: this.resetMap.bind(this),
    });

    super.$onInit();
  }
  /**/

  /* Public */
  setMarker({ lat, lng }) {
    this.provider.setMarker({ lat, lng });
  }

  resetMap() {
    this.provider.resetMap();
  }

  requestGeocoding() {
    this.provider.requestGeocoding();
  }

  requesReverseGeocode({ lat, lng }) {
    this.provider.requesReverseGeocode({ lat, lng });
  }
  /**/

  /* Private */
  /* */

  /* Observers */
  /**/
}

class GolfleetMapReverseGeocode {
  constructor() {
    this.template = template;
    this.bindings = { address: '=?' };
    this.controller = GolfleetMapReverseGeocodeController;
  }
}

angular
  .module('golfleet-map-reverse-geocode', [])
  .component('golfleetMapReverseGeocode', new GolfleetMapReverseGeocode());
