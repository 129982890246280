import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { PowerMapVehiclesController } from '@power/power-components/components/power-map-vehicles/power-map-vehicles';
import { GolfleetSmartMapVehiclesHereProvider } from './providers/here/golfleet-smart-map-vehicles.here-provider';
import { GolfleetSmartMapVehiclesLeafletProvider } from './providers/leaflet/golfleet-smart-map-vehicles.leaflet-provider';

import template from './golfleet-smart-map-vehicles.html';

class GolfleetSmartMapVehiclesController extends PowerMapVehiclesController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetSmartMapVehiclesHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetSmartMapVehiclesLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  $onInit() {
    super.$onInit();
  }

  /* Public */
  /* */

  /* Private */
  /* */
}

class GolfleetSmartMapVehicles {
  constructor() {
    this.template = template;
    this.bindings = {
      reportDataset: '=?',
    };
    this.controller = GolfleetSmartMapVehiclesController;
  }
}

angular
  .module('golfleet-smart-map-vehicles', [])
  .component('golfleetSmartMapVehicles', new GolfleetSmartMapVehicles());

export { GolfleetSmartMapVehiclesController };
