import angular from 'angular';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-popup/power-popup';
import '../golfleet-popup-terms-of-use/golfleet-popup-terms-of-use';
import '@power/power-components/components/power-share-tree/power-share-tree';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-period-preview/power-period-preview';
import '@power/power-components/components/power-map-vehicles/power-map-vehicles';
import '../golfleet-chat/golfleet-chat';
import '@power/power-components/components/power-accordion/power-accordion';

import { PowerRecordVehicleController } from '@power/power-components/components/power-record-vehicle/power-record-vehicle';

import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import template from './golfleet-record-vehicle.html';
import './golfleet-record-vehicle.scss';

class GolfleetRecordVehicleController extends PowerRecordVehicleController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'recordServices',
      'urlApi',
      'urlWebSocket',
      'trackingRealTimeServices',
      'deviceDetector',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    recordServices,
    urlApi,
    urlWebSocket,
    trackingRealTimeServices,
    deviceDetector,
  ) {
    super(
      $element,
      $ngRedux,
      $state,
      $scope,
      $http,
      recordServices,
      urlApi,
      urlWebSocket,
      trackingRealTimeServices,
      deviceDetector,
    );

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        session: behavior.session,
      }),
    )(this);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _actionFunction(itemReturn) {
    switch (itemReturn.actionType) {
      case 'exec': {
        this.confirmationPopup = {
          open: true,
          text: itemReturn.confirmTitle || 'Deseja realmente executar esta ação?',
          subtext: itemReturn.confirmMessage || '',
          confirm: () => {
            this.$.querySelector('#popup-confirmation').toggle();
            this._evtClickBtnCustomPopup('primaryBtn', 'popup-confirmation');
          },
          cancel: () => {
            this.$.querySelector('#popup-confirmation').toggle();
          },
        };
        this._evtClickPopupTab(1); // 1. Comandos, 2. Enviados
        super._actionFunction(itemReturn);
        break;
      }
      case 'activeRealTime': {
        this.$.querySelector('golfleet-popup-terms-of-use').togglePopup();
        break;
      }
      case 'shareLinkRealTime': {
        this.copiedLink = false;
        this.$.querySelector('#popup-link-real-time').toggle();
        break;
      }
      case 'addRemoveTime': {
        this.incrementDecrementMinutes = 0;
        this.stopRefreshVehicleData = true;
        this.tempEndDateRealTime = this.realTimeConfig.endDate;
        this.$.querySelector('#popup-add-remove-time').toggle();
        break;
      }
      case 'waitingRealTime': {
        this.$.querySelector('#popup-waiting-real-time').toggle();
        break;
      }
      default: {
        super._actionFunction(itemReturn);
        break;
      }
    }
  }

  _getApplicationType(application) {
    if (application === 'GOLFLEET' && this.isVideoTelemetry) return 'VIDEOTELEMETRY';
    return application;
  }

  _checkItemIsNull(type) {
    return this.dataList
      ? Object.values(this.dataList)?.filter(item => item.type === type).length === 0
      : true;
  }

  _getTabs(tabs) {
    if (this.session.isSingleSignon) {
      tabs = tabs.filter(tab => !tab.hideInSSO);
    }
    return tabs;
  }
}

class GolfleetRecordVehicle {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetRecordVehicleController;
  }
}

angular
  .module('golfleet-record-vehicle', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-dropdown',
    'power-footer',
    'power-popup',
    'golfleet-popup-terms-of-use',
    'power-share-tree',
    'power-toast',
    'power-toolbar',
    'power-crud',
    'power-period-preview',
    'power-map-vehicles',
    'golfleet-chat',
    'power-accordion',
    'power-single-checkbox',
  ])
  .component('golfleetRecordVehicle', new GolfleetRecordVehicle());

export { GolfleetRecordVehicleController };
