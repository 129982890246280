/** @typedef {import('../power-popup-users/power-popup-users').User} User */

import angular from 'angular';

import '../power-dropdown/power-dropdown';
import '../power-popup/power-popup';
import '../power-popup-users/power-popup-users';
import { CustomActionOption } from '../power-popup-custom-actions/power-popup-custom-actions';
import '../power-toast/power-toast';

import template from './power-popup-report-save-and-share.html';
import './power-popup-report-save-and-share.scss';

// #region /* Models */
export class TabItem {
  /**
   * - Constructor
   * @param {String} name
   * @param {String} label
   * @param {Boolean} selected
   */
  constructor(name, label, selected = false) {
    this.name = name;
    this.label = label;
    this.selected = selected;
  }
}

export class SharedUser {
  /**
   * - Constructor
   * @param {String} icon
   * @param {Number} userId
   * @param {String} userName
   * @param {Boolean} canEdit
   * @param {Boolean} disabled
   * @param {Boolean} isMenuItem
   */
  constructor(icon, userId, userName, userEmail, canEdit, disabled = false, isMenuItem = false) {
    this.icon = icon;
    this.userId = userId;
    this.userName = userName;
    this.userEmail = userEmail;
    this.canEdit = canEdit;
    this.disabled = disabled;
    this.isMenuItem = isMenuItem;
  }

  /**
   * Export data to save request
   */
  exportData() {
    return {
      userId: this.userId,
      userEmail: this.userEmail,
      userName: this.userName,
      canEdit: this.canEdit,
      isMenuItem: this.isMenuItem,
    };
  }
}

export class UserRouteConfig {
  /**
   * - Constructor
   * @param {String} id
   * @param {String} module
   * @param {String} reportName
   * @param {Number} fileOffsetWidth
   * @param {String} instanceOf
   * @param {String} filterConfig
   * @param {String} gridConfig
   * @param {String} createdBy
   * @param {Date} createdAt
   * @param {Array<SharedUser>} sharedUsers
   */
  constructor(
    id,
    module,
    reportName,
    fileOffsetWidth,
    instanceOf,
    filterConfig,
    gridConfig,
    createdBy,
    createdAt,
    sharedUsers = [],
  ) {
    this.id = id;
    this.module = module;
    this.reportName = reportName;
    this.fileOffsetWidth = fileOffsetWidth;
    this.instanceOf = instanceOf;
    this.filterConfig = UserRouteConfig.getFilterConditions(filterConfig);
    this.gridConfig = UserRouteConfig.getGridConfigurations(gridConfig);
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.sharedUsers = sharedUsers;
  }

  /**
   * Export data to save request
   */
  exportData() {
    return {
      _id: this.id,
      module: this.module,
      reportName: this.reportName,
      fileOffsetWidth: this.fileOffsetWidth,
      sharedUsers: this.sharedUsers.map(u => u.exportData()),
      filterConfig: JSON.stringify(this.filterConfig),
      gridConfig: JSON.stringify(this.gridConfig),
    };
  }

  exportDataTransfer(userTransfer) {
    const owner = {
      userId: userTransfer.id,
      userEmail: userTransfer.email,
    };

    return {
      _id: this.id,
      module: this.module,
      reportName: this.reportName,
      fileOffsetWidth: this.fileOffsetWidth,
      sharedUsers: this.sharedUsers.map(u => u.exportData()),
      filterConfig: JSON.stringify(this.filterConfig),
      gridConfig: JSON.stringify(this.gridConfig),
      owner,
    };
  }

  /**
   * Get icon and description from compound custom filter
   * @param {Any} item
   */
  static getCompoundCustomMetadata(item) {
    const { icon, description, activeView, activeEntity } = item;
    return { icon, description, activeView, activeEntity };
  }

  /**
   * Get filter conditions
   * @param {Any} filterConfig
   */
  static getFilterConditions(filterConfig) {
    if (!filterConfig) return null;

    return filterConfig.map(config => {
      const __metadata__ =
        config.type != 'compoundCustom'
          ? { icon: config.icon, description: config.description }
          : UserRouteConfig.getCompoundCustomMetadata(config);

      return {
        id: config.id,
        key: config.key,
        type: config.type,
        field: config.field,
        condition: config.condition,
        activeEntity: config.activeEntity,
        activeView: config.activeView,
        advanced: config.advanced,
        selectors: config.selectors,
        filters: config.filters,
        visible: config.visible,
        validateModule: config.validateModule,
        show: config.show,
        __metadata__,
      };
    });
  }

  /**
   * Get grid configurations
   * @param {Any} gridConfig
   */
  static getGridConfigurations(gridConfig) {
    if (!gridConfig) return null;

    return {
      gridMongoId: gridConfig.gridMongoId,
      gridHeaders: gridConfig.gridHeaders.map(item => ({
        field: item.field,
        exportField: item.exportField,
        sortable: item.sortable,
        show: item.show,
        type: item.type,
        title:
          gridConfig.complementHeaderText && gridConfig.replaceHeaderText
            ? (Array.isArray(item.title) ? item.title : [item.title]).map(str =>
                str.replace(gridConfig.replaceHeaderText, gridConfig.complementHeaderText),
              )
            : item.title,
        selectors: item.selectors,
        columns: item.columns,
      })),
      sortField: gridConfig.sortField,
      sortDirection: gridConfig.sortDirection,
      pageSize: gridConfig.pageSize,
    };
  }

  static removeFilterIncomparableProps(key, value) {
    const props = ['previewDescription', 'total', '$$hashKey'];
    if (props.find(item => item === key)) {
      return undefined;
    }
    return value;
  }

  static removeGridIncomparableProps(key, value) {
    const props = ['$$hashKey'];
    if (props.find(item => item === key)) {
      return undefined;
    }
    return value;
  }
}
// #endregion

/**
 * - Component Controller
 */
export class PowerPopupReportSaveAndShareController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$rootScope',
      '$scope',
      '$http',
      'urlApi',
      'reportServices',
    ];
  }

  /**
   * - Constructor
   */
  constructor($element, $ngRedux, $state, $rootScope, $scope, $http, urlApi, reportServices) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $rootScope,
      $scope,
      $http,
      urlApi,
      reportServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      const { userId, userEmail, userName, adminNavigationList, navigationList } = behavior.session;

      return Object({
        modules: behavior.session.modules,
        sessionState: { userId, userEmail, userName, adminNavigationList, navigationList },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.model = new UserRouteConfig();
    this.tabItems = [new TabItem('data', 'Dados', true), new TabItem('users', 'Usuários', false)];
    this.customActionOptions = [];

    this.userPermissions = [
      { canEdit: true, description: 'Edição', icon: 'edit' },
      { canEdit: false, description: 'Visualização', icon: 'visibility' },
    ];
  }

  // #region /* Lifecycle */
  $onInit() {
    // Tab configuration
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      loadModel: this.loadModel.bind(this),
      save: this.save.bind(this),
      quickSave: this.quickSave.bind(this),
      delete: this.delete.bind(this),
      toggleMenu: this.toggleMenu.bind(this),
      toggleDeleteConfirmation: this.toggleDeleteConfirmation.bind(this),
    });

    this.$scope.$watch(
      () => this.currentState.userReportConfig,
      this.__createCustomActionOption.bind(this),
    );
  }

  $onDestroy() {
    this.__appBehavior();
  }
  // #endregion

  // #region /* Public */
  /**
   * Load/reload model
   * @param {Boolean} isUpdate
   */
  loadModel(isUpdate) {
    this.model = new UserRouteConfig(
      isUpdate ? this.currentState.userReportId : null,
      this.currentState.routeLink,
      this.currentState.userReportName || this.currentState.routeName,
      (document.querySelector('#report-body-grid table') || {}).offsetWidth,
      this.currentState.routeName,
      this.stateConfig.filterConfig,
      this.stateConfig.gridConfig,
      isUpdate
        ? this.currentState.userReportCreatedBy
        : {
            userId: this.sessionState.userId,
            userEmail: this.sessionState.userName,
          },
      isUpdate ? this.currentState.userReportCreatedAt : new Date(),
      [],
    );

    if (isUpdate) {
      const { userReportConfig: config } = this.currentState;

      if (config && config.sharedUsers.length > 0) {
        this.model.sharedUsers = config.sharedUsers.map(
          item =>
            new SharedUser(
              'timelapse',
              item.userId,
              'Carregando...',
              item.userEmail,
              item.canEdit,
              false,
              item.isMenuItem,
            ),
        );
      }
    }
  }

  /**
   * Save User Route Configuration
   */
  save(isUpdate = false, goToDefault = false, userTransfer = null) {
    if (!isUpdate) {
      this.toggle();
    }

    this._toogleLoader(true);
    this.reportServices
      .saveUserReportConfiguration(
        userTransfer ? this.model.exportDataTransfer(userTransfer) : this.model.exportData(),
      )
      .then(response => {
        if (response.status && response.status !== 200) {
          Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
          this.$.querySelector('power-toast#save-and-share-toast').toggle(true);
        } else {
          const { data: customReportId } = response.data;
          const stateParams = {
            customReportId,
            isCustomReport: true,
          };

          Object.assign(this, {
            _toastText: `Relatório ${isUpdate ? 'atualizado' : 'salvo'} com sucesso!`,
          });
          this.$.querySelector('power-toast#save-and-share-toast').toggle(true);

          this._reloadUserMenu();

          setTimeout(() => {
            this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
            this.$state.go(this.$state.current, goToDefault ? {} : stateParams, {
              reload: true,
              inherit: false,
              notify: true,
            });
          }, 1500);
        }
      });
  }

  quickSave() {
    // Load data model
    this.loadModel();

    // Add cuerrent user as admin
    const { userId, userName, userEmail } = this.sessionState;
    this.model.sharedUsers.push(new SharedUser('', userId, userName, userEmail, true));

    return new Promise((resolve, reject) => {
      this.reportServices.saveUserReportConfiguration(this.model.exportData()).then(response => {
        if (response.status && response.status !== 200) {
          reject(new Error('Ocorreu um erro inesperado.'));
        } else {
          const { data: customReportId } = response.data;
          resolve(customReportId);
        }
      });
    });
  }

  /**
   * Open / Close pop-up
   * @param {Any} options
   */
  toggle(options) {
    if (this.$.querySelector('#popup-save-and-share').getAttribute('open') == null) {
      this.$.querySelector('#power-popup-report-save-and-share-popup-users').requestData();
      this.$.querySelector('#popup-save-and-share').toggle();
      this._selectTabItem(this.tabItems[options.tab]);
      this._scrollWorkaround('#popup-save-and-share .toolbar-report-popup .filters');
    } else {
      this.$.querySelector('#popup-save-and-share').toggle();
      setTimeout(() => {
        const [tabData] = this.tabItems;
        this._selectTabItem(this.tabItems.find(item => item.name == tabData.name));
      }, 500);
    }
  }

  toggleDeleteConfirmation() {
    this.$.querySelector('#power-popup-custom-actions-delete').toggle();
  }

  delete() {
    const { userReportConfig: config } = this.currentState;

    const deleteUserReportConfig = () => {
      this.reportServices
        .deleteUserReportConfiguration({ _id: this.currentState.userReportId })
        .then(response => {
          if (response.status && response.status !== 200) {
            Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
            this.$.querySelector('power-toast#save-and-share-toast').toggle(true);
            return;
          }

          this._toogleLoader(true);
          Object.assign(this, { _toastText: 'Relatório removido com sucesso!' });
          this.$.querySelector('power-toast#save-and-share-toast').toggle(true);

          setTimeout(() => {
            this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
            this.$state.go(this.$state.current, null, {
              reload: true,
              inherit: false,
              notify: true,
            });
          }, 1500);
        });
    };

    if (
      config &&
      config.sharedUsers.length === 1 &&
      config.sharedUsers.find(user => user.userId == this.sessionState.userId)
    ) {
      this.reportServices
        .updateUserReportConfigurationIsMenuItem({
          _id: this.currentState.userReportId,
          isMenuItem: false,
        })
        .then(response => {
          const { data } = response.data;
          if (data) {
            this._reloadUserMenu();
            deleteUserReportConfig();
          }
        });
    } else {
      deleteUserReportConfig();
    }
  }

  toggleMenu() {
    if (this.currentState.userReportConfig) {
      this._toogleLoader(true);

      this.currentState.userReportConfig.sharedUsers
        .filter(user => user.userId == this.sessionState.userId)
        .forEach(user => {
          user.isMenuItem = !user.isMenuItem;

          this.reportServices
            .updateUserReportConfigurationIsMenuItem({
              _id: this.currentState.userReportId,
              isMenuItem: user.isMenuItem,
            })
            .then(response => {
              const { data } = response.data;
              if (data) {
                this._reloadUserMenu().then(() => {
                  Object.assign(this, {
                    _toastText: `O relatório atual foi ${
                      user.isMenuItem ? 'incluído no' : 'removido do'
                    } menu lateral.`,
                  });
                  this.$.querySelector('power-toast#save-and-share-toast').toggle(true);
                  this._toogleLoader(false);
                });
              } else {
                Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
                this.$.querySelector('power-toast#save-and-share-toast').toggle(true);
                this._toogleLoader(false);
              }
            });
        });
    }
  }
  // #endregion

  // #region /* Private */
  _toogleLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _reloadUserMenu() {
    return document.querySelector('#app-navigation-menu').refreshMenuNavigationList();
  }

  /**
   * - Add selected items - users pop-up callback
   * @param {Array<User>} selectedItems
   */
  _addSelectedUsersPopupCallback(selectedItems) {
    selectedItems.forEach(item => {
      if (!this.model.sharedUsers.find(user => user.userId == item.id)) {
        this.model.sharedUsers.push(
          new SharedUser(item.icon, item.id, item.name, item.email, false),
        );
      }
    });
  }

  /**
   * - Request data - users pop-up callback
   * @param {Array<User>} userItems
   */
  _requestUsersPopupCallback(userItems) {
    this.model.sharedUsers = [];
    const { userReportConfig } = this.currentState;

    userItems.forEach(item => {
      if (item.id == this.sessionState.userId) {
        this.model.sharedUsers = [
          new SharedUser(item.icon, item.id, item.name, item.email, true, true),
          ...this.model.sharedUsers,
        ];
      } else if (userReportConfig && Array.isArray(userReportConfig.sharedUsers)) {
        const user = userReportConfig.sharedUsers.find(sharedUser => sharedUser.userId == item.id);

        if (user) {
          this.model.sharedUsers.push(
            new SharedUser(item.icon, item.id, item.name, item.email, user.canEdit, false),
          );
        }
      }
    });

    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }
  }

  /**
   * Open / Close users pop-up
   */
  _usersTogglePopup() {
    this.$.querySelector('#power-popup-report-save-and-share-popup-users').toggle();
  }

  /**
   * Select specific item
   * @param {TabItem} tab
   */
  _selectTabItem(tab) {
    this.tabItems.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  /**
   * Get selected item
   * @param {TabItem} tab
   */
  _selectedTabItem() {
    const [item] = this.tabItems.filter(tabItem => tabItem.selected);
    return item;
  }

  /**
   * Change user permission
   * @param {SharedUser} user
   * @param {Boolean} canEdit
   */
  _changeUserPermission(user, canEdit) {
    if (!user.disabled) {
      user.canEdit = canEdit;
    }
  }

  _removeSharedUser(user) {
    if (!user.disabled) {
      this.model.sharedUsers = this.model.sharedUsers.filter(u => u.userId != user.userId);
    }
  }

  _customActionConfirmation(value) {
    switch (value) {
      case 'delete':
        this.delete();
        break;
      case 'transfer':
        this.$.querySelector('#power-popup-report-save-and-share-popup-select-admin').requestData();
        this.$.querySelector('#power-popup-report-save-and-share-popup-select-admin').toggle();
        break;
      case 'unshare':
        this._removeShare();
        break;
      default:
    }

    this.$.querySelector('#power-popup-custom-actions-delete').toggle();
  }

  _removeShareAndTransferToAdmin(data) {
    this.loadModel(true);
    this._removeSharedUser({ userId: this.sessionState.userId, disabled: false });

    const [admin] = data;

    if (this.model.sharedUsers.find(user => user.userId == admin.id)) {
      this.model.sharedUsers
        .filter(user => user.userId == admin.id)
        .forEach(user => {
          user.canEdit = true;
        });
    } else {
      this.model.sharedUsers.push(
        new SharedUser(admin.icon, admin.id, admin.name, admin.email, true),
      );
    }

    this.save(true, true, admin);
    this._reloadUserMenu();
  }

  _removeShare() {
    this.loadModel(true);
    this._removeSharedUser({ userId: this.sessionState.userId, disabled: false });
    this.save(true, true, null);
    this._reloadUserMenu();
  }

  /**
   * Chrome Locked-Scroll Workaround
   * @param {String} selector
   */
  _scrollWorkaround(selector) {
    const element = this.$.querySelector(selector);
    setTimeout(() => {
      element.style.overflowY = 'hidden';
    }, 1100);
    setTimeout(() => {
      element.style.overflowY = 'auto';
    }, 1200);
  }
  // #endregion

  __createCustomActionOption() {
    if (!this.currentState.userReportConfig) return;

    const result = this.currentState.userReportConfig.sharedUsers.filter(user => user.canEdit);
    const optTransfer = new CustomActionOption('transfer', 'Transferir à um administrador');
    const optDelete = new CustomActionOption('delete', 'Excluir permanentemente');
    const optUnshare = new CustomActionOption('unshare', 'Remover meu compartilhamento');

    if (result.find(user => user.userId == this.sessionState.userId)) {
      if (result.length > 1) {
        this.customActionOptions = [optTransfer, optDelete, optUnshare];
      } else {
        this.customActionOptions = [optTransfer, optDelete];
      }
    } else {
      this.customActionOptions = [optUnshare];
    }

    const [first] = this.customActionOptions;
    first.selected = true;
  }

  _filterItemByModule(itemList) {
    if (this.modules && itemList) {
      return itemList.filter(
        item =>
          (item.visible || (item.type === 'compoundCustom' && item.show)) &&
          (!item.validateModule ||
            this.modules.some(module => item.validateModule.includes(module))),
      );
    }
    return itemList;
  }
}

export class PowerPopupReportSaveAndShare {
  constructor() {
    this.template = template;
    this.bindings = {
      reportIcon: '=?',
    };
    this.controller = PowerPopupReportSaveAndShareController;
  }
}

angular
  .module('power-popup-report-save-and-share', [
    'ngRedux',
    'power-dropdown',
    'power-popup',
    'power-popup-users',
    'power-popup-custom-actions',
    'power-toast',
  ])
  .component('powerPopupReportSaveAndShare', new PowerPopupReportSaveAndShare());
