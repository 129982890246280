/* global L: true isIframe: true */
import { PowerMapPositionsLeafletProvider } from '@power/power-components/components/power-map-positions/providers/leaflet/power-map-positions.leaflet-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-smart-map-positions.leaflet-provider.scss';

class GolfleetSmartMapPositionsLeafletProvider extends PowerMapPositionsLeafletProvider {
  constructor(context, $element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /**/

  /* Public */
  /**/

  /* Private */
  _geoJsonToMarker({ geoJson, markerIcon }) {
    const { eventoGerador } = geoJson.properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';

    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon:
            markerIcon ||
            (geoJson.properties.markerIcon === 'RouteIcon'
              ? this._createRouteIcon
              : this._createMarkerIcon)(geoJson.properties),
        }).bindPopup(`
          <div id="${!isVmaxEvent ? 'mapPopupHeader' : 'mapPopupHeaderVmax'}">
            <span>${feature.properties.placaComApelido}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${this._toDate(feature.properties.dataHora)}</span>
            </div>
            <div>
              <b>Velocidade:</b>
              <br>
              <div class="mapPopupBodyItem">
                <span>
                  <i class="material-icons"
                    style="font-size: 11px; color: ${
                      !feature.properties.ignicao ? '#980A1A' : '#4AAE4E'
                    }">
                    lens
                  </i>
                  ${feature.properties.velocidade} Km/h
                  <i class="material-icons"
                    style="font-size: 11px; color: #949494;">
                    ${
                      !feature.properties.gpsStatus
                        ? 'signal_cellular_connected_no_internet_4_bar'
                        : 'network_cell'
                    }
                  </i>
                </span>
                ${!isVmaxEvent ? '' : "<span class='vmaxTag'>VMAX</span>"}
              </div>
            </div>
            ${
              isIframe()
                ? ''
                : `
              <div style="margin-top:8px; text-align:center">
                <a class="gs-link"
                  target="_blank"
                  href="https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}">
                  Ver no StreetView
                </a>
              </div>
            `
            }
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${Number(latlng.lat).toFixed(4)} </span>
            <span> Lon: ${Number(latlng.lng).toFixed(4)} </span>
          </div>
				`),
    }).getLayers()[0];
  }
  /**/

  /* Observers */
  /**/
}

export { GolfleetSmartMapPositionsLeafletProvider };
