import {
  DashStateConfig,
  ReportStateConfig,
} from '@power/power-components/components/power-state-config/power-state-config';

const RoutesConfig = {
  login: {
    name: 'login',
    url: '/login',
    template: '<golfleet-login></golfleet-login>',
    data: {
      routeName: '',
      routeLink: 'login',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  record: {
    name: 'record',
    url: '/registro/:tail',
    template: '<power-record></power-record>',
    data: {
      routeName: '',
      routeLink: 'record',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  recordVehicle: {
    name: 'recordVehicle',
    url: '/veiculo/:tail',
    template: '<golfleet-record-vehicle></golfleet-record-vehicle>',
    data: {
      routeName: '',
      routeLink: 'recordVehicle',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  recordDriver: {
    name: 'recordDriver',
    url: '/condutor/:tail',
    template: '<golfleet-record-driver></golfleet-record-driver>',
    data: {
      routeName: '',
      routeLink: 'recordDriver',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  /* Dashboards */
  dashboard: {
    name: 'dashboard',
    url: '/dashboard',
    template: '<golfleet-dashboard></golfleet-dashboard>',
    params: {},
    data: {
      routeName: 'Dashboard',
      routeLink: 'dashboard',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'Dashboard',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  fuelModule: {
    name: 'fuelModule',
    url: '/combustivel/dashboard',
    template: '<golfleet-dashboard-fuel-module></golfleet-dashboard-fuel-module>',
    params: {},
    data: {
      routeName: 'Combustível',
      routeLink: 'fuelModule',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'FuelModule',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  speedLimitModule: {
    name: 'speedLimitModule',
    url: '/velocidade-por-via/dashboard',
    template: '<golfleet-dashboard-speed-limit></golfleet-dashboard-speed-limit>',
    params: {},
    data: {
      routeName: 'Velocidade por Via',
      routeLink: 'speedLimitModule',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'SpeedLimitModule',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  dashboardTravels: {
    name: 'dashboardTravels',
    url: '/dashboard/rodagem',
    template: '<golfleet-dashboard-route></golfleet-dashboard-route>',
    params: {},
    data: {
      routeName: 'Rodagem',
      routeLink: 'dashboardTravels',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'Travels',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  dashboardTco: {
    name: 'dashboardTco',
    url: '/dashboard/tco',
    template: '<golfleet-dashboard-route></golfleet-dashboard-route>',
    params: {},
    data: {
      routeName: 'TCO',
      routeLink: 'dashboardTco',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'Tco',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  dashboardViolation: {
    name: 'dashboardViolation',
    url: '/dashboard/infracao',
    template: '<golfleet-dashboard-route></golfleet-dashboard-route>',
    params: {},
    data: {
      routeName: 'Infrações',
      routeLink: 'dashboardViolation',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'Violation',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  dashboardUtilization: {
    name: 'dashboardUtilization',
    url: '/dashboard/utilizacao',
    template: '<golfleet-dashboard-route></golfleet-dashboard-route>',
    params: {},
    data: {
      routeName: 'Utilização',
      routeLink: 'dashboardUtilization',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'Utilization',
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  /* Entities */
  vehicles: {
    name: 'vehicles',
    url: '/lista/veiculos',
    template: '<golfleet-report-vehicles></golfleet-report-vehicles>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Veículos',
      routeLink: 'vehicles',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Vehicle',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Vehicle',
          toolbarName: 'List',
          getDataMethod: 'Vehicle/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          hasRowSelection: true,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'Vehicle/Export',
    },
  },

  vehiclesAdm: {
    name: 'vehiclesAdm',
    url: '/admin/lista/veiculos',
    template: '<golfleet-report-vehicles></golfleet-report-vehicles>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Veículos',
      routeLink: 'vehiclesAdm',
      routeSubName: 'Veículos Adm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Vehicle',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'VehicleAdm',
          toolbarName: 'List',
          getDataMethod: 'VehicleAdm/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          hasRowSelection: true,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'VehicleAdm/Export',
    },
  },

  fleetPolicyFormAdm: {
    name: 'fleetPolicyFormAdm',
    isCustomizable: false,
    url: '/form/politicas',
    template: '<golfleet-form-fleet-policy></golfleet-form-fleet-policy>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'fleetPolicyFormAdm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  fleetPolicyAdm: {
    name: 'fleetPolicyAdm',
    url: '/lista/politicas',
    template: '<power-report-fleet-policy></power-report-fleet-policy>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Políticas',
      routeLink: 'fleetPolicyAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'FleetPolicy',
          isAdm: true,
          viewMode: 'list',
          gridName: 'List',
          screenName: 'FleetPolicyAdm',
          toolbarName: 'List',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  fleetPolicyStatus: {
    name: 'fleetPolicyStatus',
    url: '/lista/status/politicas',
    template: '<golfleet-report-fleet-policy-status></golfleet-report-fleet-policy-status>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Status de Regra por Veículo',
      routeLink: 'fleetPolicyStatus',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'FleetPolicyStatus',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'FleetPolicyStatus',
          toolbarName: 'List',
          getDataMethod: 'FleetPolicy/GetFleetPolicyVehicles',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gavel',
      isCustomizable: true,
      exportMethod: 'FleetPolicy/GetFleetPolicyVehiclesExport',
    },
  },

  fleetPolicyStatusAdm: {
    name: 'fleetPolicyStatusAdm',
    url: '/admin/lista/status/politicas',
    template: '<golfleet-report-fleet-policy-status></golfleet-report-fleet-policy-status>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Status de Regra por Veículo',
      routeLink: 'fleetPolicyStatusAdm',
      routeSubName: 'Status de Regra por Veículo Adm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'FleetPolicyStatus',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'FleetPolicyStatusAdm',
          toolbarName: 'List',
          getDataMethod: 'FleetPolicyAdm/GetFleetPolicyVehicles',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gavel',
      isCustomizable: true,
      exportMethod: 'FleetPolicyAdm/GetFleetPolicyVehiclesExport',
    },
  },

  groups: {
    name: 'groups',
    url: '/lista/grupos',
    template: '<power-report-groups></power-report-groups>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Grupos',
      routeLink: 'groups',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Group',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Group',
          toolbarName: 'List',
          getDataMethod: 'Group/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_group',
      isCustomizable: true,
      exportMethod: 'Group/Export',
    },
  },

  groupsAdm: {
    name: 'groupsAdm',
    url: '/admin/lista/grupos',
    template: '<power-report-groups></power-report-groups>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Grupos',
      routeLink: 'groupsAdm',
      routeSubName: 'Grupos Adm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Group',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'GroupAdm',
          toolbarName: 'List',
          getDataMethod: 'GroupAdm/Post',
          backPagination: false,
          getDataFixedParams: {},
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
        }),
    },
    __metadata__: {
      icon: 'gs_group',
      isCustomizable: true,
      exportMethod: 'GroupAdm/Export',
    },
  },

  usersAdm: {
    name: 'usersAdm',
    url: '/admin/lista/usuarios',
    template: '<power-report></power-report>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Usuários',
      routeLink: 'usersAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'User',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'User',
          toolbarName: 'List',
          getDataMethod: 'User/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_user',
      isCustomizable: true,
      exportMethod: 'User/Export',
    },
  },

  tempDriversAdm: {
    name: 'tempDriversAdm',
    url: '/admin/lista/condutor',
    template: '<golfleet-report-drivers></golfleet-report-drivers>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Condutores',
      routeLink: 'tempDriversAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'TempDriver',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TempDriver',
          toolbarName: 'List',
          getDataMethod: 'Driver/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_driver',
      isCustomizable: true,
      exportMethod: 'Driver/Export',
    },
  },

  driversAdm: {
    name: 'driversAdm',
    url: '/admin/lista/condutores',
    template: '<golfleet-report-drivers></golfleet-report-drivers>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Condutores',
      routeLink: 'driversAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Driver',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Driver',
          toolbarName: 'List',
          getDataMethod: 'Driver/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_driver',
      isCustomizable: true,
      exportMethod: 'Driver/Export',
    },
  },

  driverFormAdm: {
    name: 'driverFormAdm',
    isCustomizable: false,
    url: '/admin/form/condutor',
    template: '<golfleet-form-driver-crud></golfleet-form-driver-crud>',
    params: {},
    data: {
      routeName: 'Condutor',
      routeLink: 'driverFormAdm',
      routeTail: null,
      stateConfig: null,
    },
    metadata: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  checklistForm: {
    name: 'checklistForm',
    isCustomizable: false,
    url: '/form/checklist/:tail?',
    template: '<golfleet-form-checklist></golfleet-form-checklist>',
    params: {
      tail: { value: null },
    },
    data: {
      routeName: 'Formulário de Checklist',
      routeLink: 'checklistForm',
      routeTail: null,
      stateConfig: null,
    },
    metadata: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  checklistReport: {
    name: 'checklistReport',
    url: '/relatorio/checklist',
    template: '<golfleet-report-checklist></golfleet-report-checklist>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Checklist',
      routeLink: 'checklistReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Checklist',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Checklist',
          toolbarName: 'Report',
          getDataMethod: 'Checklist/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_user',
      isCustomizable: true,
      exportMethod: 'Checklist/Export',
    },
  },

  reportMyAlerts: {
    name: 'reportMyAlerts',
    url: '/lista/alertas',
    template: '<golfleet-report-alerts></golfleet-report-alerts>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Meus Alertas',
      routeLink: 'reportMyAlerts',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'MyAlerts',
          toolbarName: 'Report',
          getDataMethod: 'Alert/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_alert',
      isCustomizable: true,
      exportMethod: 'Alert/Export',
    },
  },

  reportMyAlertsAdm: {
    name: 'reportMyAlertsAdm',
    url: '/admin/lista/alertas',
    template: '<golfleet-report-alerts></golfleet-report-alerts>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Alertas Públicos',
      routeLink: 'reportMyAlertsAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'MyAlertsAdm',
          toolbarName: 'Report',
          getDataMethod: 'AlertAdm/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_alert',
      isCustomizable: true,
      exportMethod: 'AlertAdm/Export',
    },
  },

  geofences: {
    name: 'geofences',
    url: '/lista/areas-de-controle',
    template: '<power-report-geofences></power-report-geofences>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Áreas de Controle',
      routeLink: 'geofences',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'GeoFence',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'GeoFence',
          toolbarName: 'List',
          getDataMethod: 'GeoFence/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_area',
      isCustomizable: true,
      exportMethod: 'GeoFence/Export',
    },
  },

  pointsOfInterest: {
    name: 'pointsOfInterest',
    url: '/lista/pontos-de-interesse',
    template: '<power-report-points-of-interest></power-report-points-of-interest>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Pontos de Interesse',
      routeLink: 'pointsOfInterest',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'PointsOfInterest',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'PointsOfInterest',
          toolbarName: 'List',
          getDataMethod: 'PointsOfInterest/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_area',
      isCustomizable: true,
      exportMethod: 'PointsOfInterest/Export',
    },
  },

  wifiListAdm: {
    name: 'wifiListAdm',
    url: '/admin/lista/wifi',
    template: '<power-report-wifi></power-report-wifi>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Configuração de Wi-fi 2.4 GHz',
      routeLink: 'wifiListAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Wifi',
          isAdm: true,
          viewMode: 'list',
          gridName: 'List',
          screenName: 'WifiAdm',
          toolbarName: 'List',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  /* Reports */
  reportGeneratedAlerts: {
    name: 'reportGeneratedAlerts',
    url: '/relatorio/alertas',
    template: '<power-report-generated-alerts></power-report-generated-alerts>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Alertas enviados',
      routeLink: 'reportGeneratedAlerts',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Grid',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'GeneratedAlerts',
          toolbarName: 'Report',
          getDataMethod: 'Alert/GetAlertReport',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_alert',
      isCustomizable: true,
      exportMethod: 'Alert/Export',
    },
  },

  utilizationReport: {
    name: 'utilizationReport',
    url: '/utilizacao-avancado/relatorio',
    template: '<power-report-utilization has-dvr></power-report-utilization>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Utilização Avançado',
      routeLink: 'utilizationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Utilization',
          toolbarName: 'Report',
          getDataMethod: 'Utilizations/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'Utilizations/Export',
    },
  },

  tempUtilizationReport: {
    name: 'tempUtilizationReport',
    url: '/dashboard/relatorio/utilizacao',
    template: '<golfleet-report-temp-utilization></golfleet-report-temp-utilization>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Utilização',
      routeSubName: 'Relatório de Utilização Antigo',
      routeLink: 'tempUtilizationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TempUtilization',
          toolbarName: 'Report',
          getDataMethod: 'Utilization/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'Utilization/Export',
      endReportDate: '03 de junho de 2024',
      newReportRouteName: 'utilizationReport',
      newReportModuleName: 'utilização',
    },
  },

  utilizationSimplified: {
    name: 'utilizationSimplified',
    url: '/utilizacao/relatorio',
    template: '<power-report-utilization></power-report-utilization>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Utilização',
      routeLink: 'utilizationSimplified',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'UtilizationSimplified',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'UtilizationSimplified',
          toolbarName: 'List',
          getDataMethod: 'Utilizations/PostSimplified',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: $stateParams.filters,
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'Utilizations/ExportSimplified',
    },
  },

  tempUtilizationDetailReport: {
    name: 'tempUtilizationDetailReport',
    url: '/relatorio/utilizacao/:tail',
    template: '<golfleet-report-base></golfleet-report-base>',
    data: {
      routeName: '',
      routeLink: 'tempUtilizationDetailReport',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  kmControlReport: {
    name: 'kmControlReport',
    url: '/dashboard/relatorio/controle-km',
    template: '<golfleet-report-base></golfleet-report-base>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Controle de Km',
      routeLink: 'kmControlReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'KmControl',
          toolbarName: 'Report',
          getDataMethod: 'KmControl/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'KmControl/Export',
      endReportDate: '03 de junho de 2024',
      newReportRouteName: 'travelsByVehicle',
      newReportModuleName: 'rodagem',
    },
  },

  activitiesReport: {
    name: 'activitiesReport',
    url: '/dashboard/relatorio/atividades',
    template: '<golfleet-report-base></golfleet-report-base>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Atividades',
      routeLink: 'activitiesReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Activities',
          toolbarName: 'Report',
          getDataMethod: 'Activities/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'Activities/Export',
      endReportDate: '03 de junho de 2024',
      newReportRouteName: 'travelsByVehicle',
      newReportModuleName: 'rodagem',
    },
  },

  periodActivitiesReport: {
    name: 'periodActivitiesReport',
    url: '/dashboard/relatorio/atividades-por-periodo',
    template: '<golfleet-report-base></golfleet-report-base>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Atividades por Período',
      routeLink: 'periodActivitiesReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'PeriodActivities',
          toolbarName: 'Report',
          getDataMethod: 'PeriodActivities/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'PeriodActivities/Export',
      endReportDate: '03 de junho de 2024',
      newReportRouteName: 'travelsByVehicle',
      newReportModuleName: 'rodagem',
    },
  },

  periodActivitiesDetailReport: {
    name: 'periodActivitiesDetailReport',
    url: '/relatorio/atividades-por-periodo/:tail',
    template: '<golfleet-report-base></golfleet-report-base>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'periodActivitiesDetailReport',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  positionsReport: {
    name: 'positionsReport',
    url: '/relatorio/posicoes',
    template: '<power-report-positions></power-report-positions>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'positionsReport',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  positionsReportAdm: {
    name: 'positionsReportAdm',
    url: '/admin/relatorio/posicoes',
    template: '<power-report-positions></power-report-positions>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'positionsReportAdm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  golcamReport: {
    name: 'golcamReport',
    url: '/relatorio/reconhecimento-facial',
    template: '<golfleet-report-golcam></golfleet-report-golcam>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'golcamReport',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  golcamReportAdm: {
    name: 'golcamReportAdm',
    url: '/admin/relatorio/reconhecimento-facial',
    template: '<golfleet-report-golcam></golfleet-report-golcam>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'golcamReportAdm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  rankingSpeedLimitReportByVehicle: {
    name: 'rankingSpeedLimitReportByVehicle',
    url: '/velocidade-por-via/relatorio/ranking/veiculos',
    template: '<golfleet-report-ranking-speed-limit></golfleet-report-ranking-speed-limit>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Infrações VVia por Veículo',
      routeLink: 'rankingSpeedLimitReportByVehicle',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'RankingSpeedLimitReportByVehicle',
          toolbarName: 'List',
          getDataMethod: 'SpeedLimitModule/GetInfratorRankingData',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'SpeedLimitModule/ExportInfratorRanking',
    },
  },
  rankingSpeedLimitReportByDriver: {
    name: 'rankingSpeedLimitReportByDriver',
    url: '/velocidade-por-via/relatorio/ranking/condutores',
    template: '<golfleet-report-ranking-speed-limit></golfleet-report-ranking-speed-limit>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Infrações VVia por Condutores',
      routeLink: 'rankingSpeedLimitReportByDriver',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'RankingSpeedLimitReportByDriver',
          toolbarName: 'List',
          getDataMethod: 'SpeedLimitModule/GetInfratorRankingData',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'SpeedLimitModule/ExportInfratorRanking',
    },
  },
  rankingSpeedLimitReportByRoute: {
    name: 'rankingSpeedLimitReportByRoute',
    url: '/velocidade-por-via/relatorio/ranking/vias',
    template: '<golfleet-report-ranking-speed-limit></golfleet-report-ranking-speed-limit>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Infrações VVia por Via',
      routeLink: 'rankingSpeedLimitReportByRoute',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'RankingSpeedLimitReportByRoute',
          toolbarName: 'List',
          getDataMethod: 'SpeedLimitModule/GetInfratorRankingData',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'SpeedLimitModule/ExportInfratorRanking',
    },
  },

  rankingSpeedLimitDetailReport: {
    name: 'rankingSpeedLimitDetailReport',
    url: '/velocidade-por-via/relatorio/ranking/:tail',
    template:
      '<golfleet-report-ranking-details-speed-limit></golfleet-report-ranking-details-speed-limit>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'rankingSpeedLimitDetailReport',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: 'SpeedLimitModule/ExportDetailInfratorRanking',
    },
  },

  violationsSpeedLimitReport: {
    name: 'violationsSpeedLimitReport',
    url: '/velocidade-por-via/relatorio/infracoes',
    template:
      '<golfleet-report-ranking-details-speed-limit></golfleet-report-ranking-details-speed-limit>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Infrações VVia',
      routeLink: 'violationsSpeedLimitReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Grid',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'ViolationsSpeedLimitReport',
          toolbarName: 'List',
          getDataMethod: 'SpeedLimitModule/GetInfratorRankingDetailData',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'SpeedLimitModule/ExportDetailInfratorRanking',
    },
  },

  fuelSupplyManagementReport: {
    name: 'fuelSupplyManagementReport',
    url: '/combustivel/relatorio/abastecimentos',
    template: '<golfleet-report-fuel-supply-management></golfleet-report-fuel-supply-management>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Abastecimentos',
      routeLink: 'fuelSupplyManagementReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'FuelSupplyManagementReport',
          toolbarName: 'List',
          getDataMethod: 'FuelModule/GetResultFuelSupplyManagement',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'FuelModule/ExportFuelSupplyManagement',
    },
  },

  consumptionBySegmentReport: {
    name: 'consumptionBySegmentReport',
    url: '/combustivel/relatorio/consumo',
    template: '<golfleet-report-consumption-by-segment></golfleet-report-consumption-by-segment>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório Consumo por Trecho',
      routeLink: 'consumptionBySegmentReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'ConsuptionBySegmentReport',
          toolbarName: 'List',
          getDataMethod: 'FuelModule/GetResultConsuptionBySegment',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fuel',
      isCustomizable: true,
      exportMethod: 'FuelModule/ExportConsuptionBySegment',
    },
  },

  reports: {
    name: 'reports',
    url: '/central/relatorios',
    template: '<golfleet-report-reports></golfleet-report-reports>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Central de Relatórios',
      routeLink: 'reports',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Reports',
          toolbarName: 'List',
          getDataMethod: 'UserReportConfiguration/GetUserReportConfigurationList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: '',
    },
  },

  reportsUser: {
    name: 'reportsUser',
    url: '/central/meus-relatorios',
    template: '<golfleet-report-reports></golfleet-report-reports>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Meus Relatórios',
      routeModule: 'Relatórios Avançados',
      routeLink: 'reportsUser',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Reports',
          toolbarName: 'List',
          getDataMethod: 'UserReportConfiguration/GetUserReportConfigurationList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: '',
    },
  },

  reportsShare: {
    name: 'reportsShare',
    url: '/central/relatorios-compartilhados',
    template: '<golfleet-report-reports></golfleet-report-reports>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Compartilhados comigo',
      routeModule: 'Relatórios Avançados',
      routeLink: 'reportsShare',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Reports',
          toolbarName: 'List',
          getDataMethod: 'UserReportConfiguration/GetUserReportConfigurationList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: '',
    },
  },

  reportsTips: {
    name: 'reportsTips',
    url: '/central/dicas',
    template: '<golfleet-report-reports-tips></golfleet-report-reports-tips>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Dicas',
      routeModule: 'Relatórios Avançados',
      routeLink: 'reportsTips',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  travelsByDriver: {
    name: 'travelsByDriver',
    url: '/rodagem/relatorio/condutor',
    template: '<power-report-travels></power-report-travels>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Rodagem por Condutor',
      routeLink: 'travelsByDriver',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TravelsByDriver',
          toolbarName: 'Report',
          getDataMethod: 'TravelsByDriver/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_speed',
      isCustomizable: true,
      exportMethod: 'TravelsByDriver/Export',
    },
  },

  travelsByGroup: {
    name: 'travelsByGroup',
    url: '/rodagem/relatorio/grupo',
    template: '<power-report-travels></power-report-travels>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Rodagem por Grupo',
      routeLink: 'travelsByGroup',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TravelsByGroup',
          toolbarName: 'Report',
          getDataMethod: 'TravelsByGroup/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_speed',
      isCustomizable: true,
      exportMethod: 'TravelsByGroup/Export',
    },
  },

  travelsByVehicle: {
    name: 'travelsByVehicle',
    url: '/rodagem/relatorio/veiculos',
    template: '<power-report-travels></power-report-travels>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Rodagem por Veículo',
      routeLink: 'travelsByVehicle',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TravelsByVehicle',
          toolbarName: 'Report',
          getDataMethod: 'TravelsByVehicle/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_speed',
      isCustomizable: true,
      exportMethod: 'TravelsByVehicle/Export',
    },
  },

  travelsByVehicleDriver: {
    name: 'travelsByVehicleDriver',
    url: '/rodagem/relatorio/veiculos/condutor',
    template: '<power-report-travels></power-report-travels>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Rodagem por Veículo e Condutor',
      routeLink: 'travelsByVehicleDriver',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TravelsByVehicleDriver',
          toolbarName: 'Report',
          getDataMethod: 'TravelsByVehicleDriver/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_speed',
      isCustomizable: true,
      exportMethod: 'TravelsByVehicleDriver/Export',
    },
  },

  travelsByUtilization: {
    name: 'travelsByUtilization',
    url: '/rodagem/relatorio/utilizacoes',
    template: '<golfleet-report-temp-utilizations></golfleet-report-temp-utilizations>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Utilização',
      routeLink: 'travelsByUtilization',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TravelsByUtilization',
          toolbarName: 'Report',
          getDataMethod: 'TravelsByUtilization/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_speed',
      isCustomizable: true,
      exportMethod: 'TravelsByUtilization/Export',
    },
  },

  tcoRentReport: {
    name: 'tcoRentReport',
    url: '/tco/relatorio/aluguel',
    template: '<golfleet-report-tco-rent></golfleet-report-tco-rent>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Aluguel',
      routeLink: 'tcoRentReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoRentReport',
          toolbarName: 'List',
          getDataMethod: 'Rent/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Rent/Export',
    },
  },

  tcoTicketReport: {
    name: 'tcoTicketReport',
    url: '/tco/relatorio/multa',
    template: '<golfleet-report-tco-ticket></golfleet-report-tco-ticket>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Multa',
      routeLink: 'tcoTicketReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoTicketReport',
          toolbarName: 'List',
          getDataMethod: 'Ticket/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Ticket/Export',
    },
  },

  tcoAccidentReport: {
    name: 'tcoAccidentReport',
    url: '/tco/relatorio/sinistro',
    template: '<golfleet-report-tco-accident></golfleet-report-tco-accident>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Sinistro',
      routeLink: 'tcoAccidentReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoAccidentReport',
          toolbarName: 'List',
          getDataMethod: 'Accident/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Accident/Export',
    },
  },

  tcoAccidentForm: {
    name: 'tcoAccidentForm',
    isCustomizable: false,
    url: '/form/sinistros',
    template: '<golfleet-form-tco-accidents-crud></golfleet-form-tco-accidents-crud>',
    params: {},
    data: {
      routeName: 'Sinistro',
      routeLink: 'tcoAccidentForm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  tcoEntryReport: {
    name: 'tcoEntryReport',
    url: '/tco/relatorio/lancamento',
    template: '<golfleet-report-tco-entry></golfleet-report-tco-entry>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Lançamento',
      routeLink: 'tcoEntryReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoEntryReport',
          toolbarName: 'List',
          getDataMethod: 'Entry/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: $stateParams.filters,
          backPagination: false,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Entry/Export',
    },
  },

  tcoCostReport: {
    name: 'tcoCostReport',
    url: '/tco/relatorio/custo',
    template: '<golfleet-report-tco-cost></golfleet-report-tco-cost>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'TCO por veículo e competência',
      routeLink: 'tcoCostReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoCostReport',
          toolbarName: 'List',
          getDataMethod: 'Cost/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Cost/Export',
    },
  },

  tcoParkingReport: {
    name: 'tcoParkingReport',
    url: '/tco/relatorio/estacionamento',
    template: '<golfleet-report-tco-parking></golfleet-report-tco-parking>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Estacionamento',
      routeLink: 'tcoParkingReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoParkingReport',
          toolbarName: 'List',
          getDataMethod: 'Parking/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Parking/Export',
    },
  },

  tcoTollReport: {
    name: 'tcoTollReport',
    url: '/tco/relatorio/pedagio',
    template: '<golfleet-report-tco-toll></golfleet-report-tco-toll>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Pedágio',
      routeLink: 'tcoTollReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'TcoTollReport',
          toolbarName: 'List',
          getDataMethod: 'Toll/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_tco',
      isCustomizable: true,
      exportMethod: 'Toll/Export',
    },
  },

  realTimeVehicles: {
    name: 'realTimeVehicles',
    url: '/relatorio/acompanhamento',
    template: '<golfleet-report-tracking-real-time></golfleet-report-tracking-real-time>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Acompanhamento em tempo real',
      routeLink: 'realTimeVehicles',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RealTimeVehicle',
          toolbarName: 'Report',
          getDataMethod: 'RealTimeVehicle/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: false,
      exportMethod: 'RealTimeVehicle/Export',
    },
  },

  realTimeVehiclesHistory: {
    name: 'realTimeVehiclesHistory',
    url: '/relatorio/historico-acompanhamento',
    template: '<golfleet-report-tracking-real-time></golfleet-report-tracking-real-time>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de acompanhamento em tempo real',
      routeLink: 'realTimeVehiclesHistory',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RealTimeVehicleHistory',
          toolbarName: 'Report',
          getDataMethod: 'RealTimeVehicle/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'RealTimeVehicle/Export',
    },
  },

  realTimeVehiclesHistoryAdm: {
    name: 'realTimeVehiclesHistoryAdm',
    url: '/admin/relatorio/historico-acompanhamento',
    template: '<golfleet-report-tracking-real-time></golfleet-report-tracking-real-time>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de uso por evento',
      routeLink: 'realTimeVehiclesHistoryAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RealTimeVehicleHistoryAdm',
          toolbarName: 'Report',
          getDataMethod: 'RealTimeVehicleAdm/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: false,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'RealTimeVehicleAdm/Export',
    },
  },

  realTimePermissionAdm: {
    name: 'realTimePermissionAdm',
    url: '/admin/relatorio/configuracao-acompanhamento',
    template: '<golfleet-report-tracking-real-time></golfleet-report-tracking-real-time>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Acompanhamento em tempo real',
      routeLink: 'realTimePermissionAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RealTimePermissionAdm',
          toolbarName: 'Report',
          getDataMethod: 'RealTimeVehicleAdm/GetRealTimePermissionList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          hasRowSelection: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'RealTimeVehicleAdm/ExportRealTimePermission',
    },
  },

  realTimeGroupByVehiclesAdm: {
    name: 'realTimeGroupByVehiclesAdm',
    url: '/admin/relatorio/acompanhamento-placa',
    template: '<golfleet-report-tracking-real-time></golfleet-report-tracking-real-time>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de uso por placa',
      routeLink: 'realTimeGroupByVehiclesAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RealTimeGroupByVehiclesAdm',
          toolbarName: 'Report',
          getDataMethod: 'RealTimeVehicleAdm/GetRealTimeClientDealerVehicleList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: false,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'RealTimeVehicleAdm/ExportRealTimeClientDealerVehicle',
    },
  },

  realTimeGroupByClientDealerAdm: {
    name: 'realTimeGroupByClientDealerAdm',
    url: '/admin/relatorio/acompanhamento-cliente',
    template: '<golfleet-report-tracking-real-time></golfleet-report-tracking-real-time>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de uso cliente/contratante',
      routeLink: 'realTimeGroupByClientDealerAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RealTimeGroupByClientDealerAdm',
          toolbarName: 'Report',
          getDataMethod: 'RealTimeVehicleAdm/GetRealTimeClientDealerList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          hasRowSelection: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'RealTimeVehicleAdm/ExportRealTimeClientDealer',
    },
  },

  violationRankingReportSPAL: {
    name: 'violationRankingReportSPAL',
    url: '/relatorio/infracoes/ranking/spal',
    template: '<golfleet-report-base></golfleet-report-base>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Ranking de Infrações',
      routeLink: 'violationRankingReportSPAL',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'ViolationRankingReportSPAL',
          toolbarName: 'Report',
          getDataMethod: 'ViolationRankingReportSPAL/GetViolationRankingReportSPAL',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: false,
      exportMethod: 'ViolationRankingReportSPAL/Export',
    },
  },

  visitReport: {
    name: 'visitReport',
    url: '/relatorio/visitas',
    template: '<golfleet-report-visit></golfleet-report-visit>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Visitas',
      routeLink: 'visitReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'VisitReport',
          toolbarName: 'Report',
          getDataMethod: 'VisitReport/GetVisitReport',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'VisitReport/Export',
    },
  },

  revisionPlan: {
    name: 'revisionPlan',
    url: '/manutencao/relatorio/planos',
    template: '<golfleet-report-revision-plan></golfleet-report-revision-plan>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Planos de revisão',
      routeLink: 'revisionPlan',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RevisionPlan',
          toolbarName: 'Report',
          getDataMethod: 'Maintenance/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: null,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'Maintenance/ExportRevisionPlan',
    },
  },

  revisionStatus: {
    name: 'revisionStatus',
    url: '/manutencao/relatorio/revisoes',
    template: '<golfleet-report-revision-status></golfleet-report-revision-status>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Status de revisão por veículo',
      routeLink: 'revisionStatus',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RevisionStatus',
          toolbarName: 'Report',
          getDataMethod: 'Maintenance/GetRevisionVehicles',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: null,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'Maintenance/ExportRevisionVehicles',
    },
  },

  revisionHistory: {
    name: 'revisionHistory',
    url: '/manutencao/relatorio/historico',
    template: '<golfleet-report-revision-history></golfleet-report-revision-history>',
    params: {
      isCustomReport: false,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Histórico de manutenções',
      routeLink: 'revisionHistory',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'RevisionHistory',
          toolbarName: 'Report',
          getDataMethod: 'Maintenance/GetRevisionHistory',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          hasRowSelection: true,
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'Maintenance/ExportRevisionHistory',
    },
  },

  workshops: {
    name: 'workshops',
    url: '/lista/oficinas',
    template: '<golfleet-report-workshops></golfleet-report-workshops>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Oficinas',
      routeLink: 'workshops',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'List',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Workshop',
          toolbarName: 'List',
          getDataMethod: 'Workshop/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: null,
          hasRowSelection: true,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'Workshop/Export',
    },
  },

  revisionPlanForm: {
    name: 'revisionPlanForm',
    isCustomizable: false,
    url: '/form/plano-revisao',
    template: '<golfleet-form-revision-plan-crud></golfleet-form-revision-plan-crud>',
    params: {},
    data: {
      routeName: 'Plano de revisão',
      routeLink: 'revisionPlanForm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  revisionVehicleForm: {
    name: 'revisionVehicleForm',
    isCustomizable: false,
    url: '/form/revisao',
    template: '<golfleet-form-revision-vehicle-crud></golfleet-form-revision-vehicle-crud>',
    params: {},
    data: {
      routeName: 'Revisão',
      routeLink: 'revisionVehicleForm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  tempViolationReport: {
    name: 'tempViolationReport',
    url: '/dashboard/relatorio/infracoes',
    template: '<golfleet-report-temp-violation></golfleet-report-temp-violation>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Infrações',
      routeLink: 'tempViolationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'TempViolation',
          toolbarName: 'Report',
          getDataMethod: 'Violation/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'Violation/Export',
      endReportDate: '03 de junho de 2024',
      newReportRouteName: 'detailedViolationReport',
      newReportModuleName: 'infrações',
    },
  },

  detailedViolationReport: {
    name: 'detailedViolationReport',
    url: '/relatorio/infracoes/detalhado',
    template: '<power-report-unitary-violation></power-report-unitary-violation>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Relatório de Infrações Detalhado',
      routeLink: 'detailedViolationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'DetailedViolation',
          toolbarName: 'Report',
          getDataMethod: 'UnitaryViolation/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
          hasMaxRows: true,
          maxRowsQty: 50000,
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'UnitaryViolation/Export',
    },
  },

  violationByDriver: {
    name: 'violationByDriver',
    url: '/infracoes/relatorio/condutor',
    template: '<golfleet-report-violation></golfleet-report-violation>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Infração por Condutor',
      routeLink: 'violationByDriver',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'ViolationByDriver',
          toolbarName: 'Report',
          getDataMethod: 'ViolationByDriver/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          hasRowSelection: false,
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'ViolationByDriver/Export',
    },
  },

  violationByVehicle: {
    name: 'violationByVehicle',
    url: '/infracoes/relatorio/veiculos',
    template: '<golfleet-report-violation></golfleet-report-violation>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Infração por Veículo',
      routeLink: 'violationByVehicle',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'ViolationByVehicle',
          toolbarName: 'Report',
          getDataMethod: 'ViolationByVehicle/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          hasRowSelection: false,
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'ViolationByVehicle/Export',
    },
  },

  violationByVehicleDriver: {
    name: 'violationByVehicleDriver',
    url: '/infracoes/relatorio/veiculos/condutor',
    template: '<golfleet-report-violation></golfleet-report-violation>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Infração por Veículo e Condutor',
      routeLink: 'violationByVehicleDriver',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'ViolationByVehicleDriver',
          toolbarName: 'Report',
          getDataMethod: 'ViolationByVehicleDriver/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          hasRowSelection: false,
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'ViolationByVehicleDriver/Export',
    },
  },

  violationByGroup: {
    name: 'violationByGroup',
    url: '/infracoes/relatorio/grupo',
    template: '<golfleet-report-violation></golfleet-report-violation>',
    params: {
      isCustomReport: true,
      customReportId: '',
      filters: [],
    },
    data: {
      routeName: 'Relatório de Infração por Grupo',
      routeLink: 'violationByGroup',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'ViolationByGroup',
          toolbarName: 'Report',
          getDataMethod: 'ViolationByGroup/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
          hasRowSelection: false,
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'ViolationByGroup/Export',
    },
  },

  motionLog: {
    name: 'motionLog',
    url: '/infracoes/relatorio/fleet-rec',
    template: '<golfleet-report-motion-log></golfleet-report-motion-log>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'motionLog',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: 'MotionLog/Export',
    },
  },

  suspectedAccident: {
    name: 'suspectedAccident',
    url: '/relatorio/suspeitas-de-acidente',
    template: '<golfleet-report-suspected-accident></golfleet-report-suspected-accident>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Suspeitas de Acidente',
      routeLink: 'suspectedAccident',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'SuspectedAccident',
          toolbarName: 'Report',
          getDataMethod: 'SuspectedAccident/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_area',
      isCustomizable: true,
      exportMethod: 'SuspectedAccident/Export',
    },
  },

  /* OS */

  orderServiceReport: {
    name: 'orderServiceReport',
    url: '/ordem-de-servico/relatorio',
    template: '<golfleet-report-order-service></golfleet-report-order-service>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Solicitações',
      routeLink: 'orderServiceReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'OrderService',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'OrderService',
          toolbarName: 'Report',
          getDataMethod: 'OrderService/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_area',
      isCustomizable: true,
      exportMethod: 'OrderService/Export',
    },
  },

  orderServiceForm: {
    name: 'orderServiceForm',
    isCustomizable: false,
    url: '/ordem-de-servico/form',
    template: '<golfleet-form-order-service></golfleet-form-order-service>',
    params: {},
    data: {
      routeName: 'Ordem de Serviço',
      routeLink: 'orderServiceForm',
      routeTail: null,
      stateConfig: null,
    },
    metadata: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  requesterReport: {
    name: 'requesterReport',
    url: '/ordem-de-servico/solicitante',
    template: '<golfleet-report-requester></golfleet-report-requester>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Solicitantes',
      routeLink: 'requesterReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Requester',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Requester',
          toolbarName: 'List',
          getDataMethod: 'OrderService/GetRequesters',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_area',
      isCustomizable: true,
      exportMethod: 'OrderService/ExportRequesters',
    },
  },
  /* OS */

  /* Addons */
  fuelModuleInfoAddon: {
    name: 'fuelModuleInfoAddon',
    url: '/combustivel/info',
    template: '<golfleet-dashboard-info-addon></golfleet-dashboard-info-addon>',
    params: {
      routeId: null,
      serviceId: null,
      serviceName: null,
      serviceIcon: '',
    },

    data: {
      routeName: 'Combustível',
      routeLink: 'fuelModuleInfoAddon',
      routeTail: null,
      stateConfig: $stateParams => ({
        routeId: $stateParams.routeId,
        serviceId: $stateParams.serviceId,
        serviceName: $stateParams.serviceName,
        serviceIcon: $stateParams.serviceIcon,
        isAdm: false,
        screen: 'FuelModule',
      }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  speedLimitModuleInfoAddon: {
    name: 'speedLimitModuleInfoAddon',
    url: '/velocidade-por-via/info',
    template: '<golfleet-dashboard-info-addon></golfleet-dashboard-info-addon>',
    params: {
      routeId: null,
      serviceId: null,
      serviceName: null,
      serviceIcon: '',
    },

    data: {
      routeName: 'Velocidade por Via',
      routeLink: 'speedLimitModuleInfoAddon',
      routeTail: null,
      stateConfig: $stateParams => ({
        routeId: $stateParams.routeId,
        serviceId: $stateParams.serviceId,
        serviceName: $stateParams.serviceName,
        serviceIcon: $stateParams.serviceIcon,
        isAdm: false,
        screen: 'SpeedLimitModule',
      }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  maintenanceModuleInfoAddon: {
    name: 'maintenanceModuleInfoAddon',
    url: '/manutencao/info',
    template: '<golfleet-dashboard-info-addon></golfleet-dashboard-info-addon>',
    params: {
      routeId: null,
      serviceId: null,
      serviceName: null,
      serviceIcon: '',
    },

    data: {
      routeName: 'Manutenção',
      routeLink: 'maintenanceModuleInfoAddon',
      routeTail: null,
      stateConfig: $stateParams => ({
        routeId: $stateParams.routeId,
        serviceId: $stateParams.serviceId,
        serviceName: $stateParams.serviceName,
        serviceIcon: $stateParams.serviceIcon,
        isAdm: false,
        screen: 'MaintenanceModule',
      }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  tcoModuleInfoAddon: {
    name: 'tcoModuleInfoAddon',
    url: '/tco/info',
    template: '<golfleet-dashboard-info-addon></golfleet-dashboard-info-addon>',
    params: {
      routeId: null,
      serviceId: null,
      serviceName: null,
      serviceIcon: '',
    },

    data: {
      routeName: 'TCO',
      routeLink: 'tcoModuleInfoAddon',
      routeTail: null,
      stateConfig: $stateParams => ({
        routeId: $stateParams.routeId,
        serviceId: $stateParams.serviceId,
        serviceName: $stateParams.serviceName,
        serviceIcon: $stateParams.serviceIcon,
        isAdm: false,
        screen: 'TcoModule',
      }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  dashboardAnnouncements: {
    name: 'dashboardAnnouncements',
    url: '/comunicado/dashboard',
    template: '<golfleet-dashboard-announcements></golfleet-dashboard-announcements>',
    params: {},
    data: {
      routeName: 'Conteúdos e Notícias',
      routeLink: 'dashboardAnnouncements',
      routeTail: null,
      stateConfig: () =>
        new DashStateConfig({
          screenName: 'Dashboard',
          toolbarName: 'Announcements',
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  announcementForm: {
    name: 'announcementForm',
    isCustomizable: false,
    url: '/comunicado/form',
    template: '<golfleet-form-announcement-crud></golfleet-form-announcement-crud>',
    params: {},
    data: {
      routeName: 'Comunicados',
      routeLink: 'announcementForm',
      routeTail: null,
      stateConfig: null,
    },
    metadata: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  reportAnnouncementsAdm: {
    name: 'reportAnnouncementsAdm',
    url: '/admin/lista/comunicados',
    template: '<golfleet-report-announcements></golfleet-report-announcements>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Meus Comunicados',
      routeLink: 'reportAnnouncementsAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Announcement',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Announcements',
          toolbarName: 'Report',
          getDataMethod: 'Announcement/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: true,
          getDataFilters: [],
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_message',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  driversAppStatusAdm: {
    name: 'driversAppStatusAdm',
    url: '/admin/lista/statusapp-driver',
    template: '<golfleet-report-drivers-app-status></golfleet-report-drivers-app-status>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Status do App Golfleet Driver',
      routeLink: 'driversAppStatusAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'DriversAppStatus',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'DriversAppStatusAdm',
          toolbarName: 'List',
          getDataMethod: 'DriverStatusAppAdm/GetDriverStatusAppList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_driver',
      isCustomizable: true,
      exportMethod: 'DriverStatusAppAdm/ExportDriverStatusAppList',
    },
  },

  golfleetIdAppStatusAdm: {
    name: 'golfleetIdAppStatusAdm',
    url: '/admin/lista/statusapp-golfleetid',
    template: '<golfleet-report-drivers-app-status></golfleet-report-drivers-app-status>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Status do App Golfleet ID',
      routeLink: 'golfleetIdAppStatusAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'GolfleetIdAppStatus',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'GolfleetIdAppStatusAdm',
          toolbarName: 'List',
          getDataMethod: 'DriverStatusAppAdm/GetGolfleetIdAppList',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_driver',
      isCustomizable: true,
      exportMethod: 'DriverStatusAppAdm/ExportGolfleetIdAppList',
    },
  },

  golfleetIdPermissionHistoricAdm: {
    name: 'golfleetIdPermissionHistoricAdm',
    url: '/admin/lista/permission-historic-golfleetid',
    template: '<golfleet-report-drivers-gid-historic></golfleet-report-drivers-gid-historic>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de Permissões App Golfleet ID',
      routeLink: 'golfleetIdPermissionHistoricAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'GolfleetIdPermissionHistoric',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'GolfleetIdPermissionHistoricAdm',
          toolbarName: 'List',
          getDataMethod: 'DriverStatusAppAdm/GetGolfleetIdPermissionsHistoric',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          getDataFilters: [],
          backPagination: false,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: 'gs_driver',
      isCustomizable: true,
      exportMethod: 'DriverStatusAppAdm/ExportGolfleetIdPermissionsHistoric',
    },
  },

  reportBlockages: {
    name: 'reportBlockages',
    url: '/bloqueio/relatorio',
    template: '<golfleet-report-blockages></golfleet-report-blockages>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de Bloqueio',
      routeLink: 'reportBlockages',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Blockage',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Blockage',
          toolbarName: 'Report',
          getDataMethod: 'Blockage/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          hasRowSelection: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'Blockage/Export',
    },
  },
  reportBlockagesAdm: {
    name: 'reportBlockagesAdm',
    url: '/admin/bloqueio/relatorio',
    template: '<golfleet-report-blockages></golfleet-report-blockages>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Histórico de Bloqueio',
      routeLink: 'reportBlockagesAdm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Blockage',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'BlockageAdm',
          toolbarName: 'Report',
          getDataMethod: 'BlockageAdm/Post',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          hasRowSelection: false,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: true,
      exportMethod: 'BlockageAdm/Export',
    },
  },

  /* Golfeet Smart */
  recordVehicleSmart: {
    name: 'recordVehicleSmart',
    url: '/smart/veiculo/:tail',
    template: '<golfleet-smart-record-vehicle></golfleet-smart-record-vehicle>',
    data: {
      routeName: '',
      routeLink: 'recordVehicleSmart',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  vehiclesSmart: {
    name: 'vehiclesSmart',
    url: '/smart/lista/veiculos',
    template: '<golfleet-smart-report-vehicles></golfleet-smart-report-vehicles>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Veículos',
      routeLink: 'vehiclesSmart',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Vehicle',
          isAdm: false,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'Vehicle',
          toolbarName: 'List',
          getDataMethod: 'Vehicle/GetVehiclesSimplified',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          hasRowSelection: true,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'Vehicle/ExportVehiclesSimplified',
    },
  },
  vehiclesSmartAdm: {
    name: 'vehiclesSmartAdm',
    url: '/smart/admin/lista/veiculos',
    template: '<golfleet-smart-report-vehicles></golfleet-smart-report-vehicles>',
    params: {
      isCustomReport: false,
      customReportId: '',
    },
    data: {
      routeName: 'Veículos',
      routeLink: 'vehiclesSmartAdm',
      routeSubName: 'Veículos Adm',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Vehicle',
          isAdm: true,
          viewMode: 'grid',
          gridName: 'List',
          screenName: 'VehicleAdm',
          toolbarName: 'List',
          getDataMethod: 'VehicleAdm/GetVehiclesSimplified',
          isCustomReport: $stateParams.isCustomReport,
          customReportId: $stateParams.customReportId,
          backPagination: false,
          hasRowSelection: true,
          getDataFilters: [],
          getDataFixedParams: {},
          checkDefaulterRules: true,
        }),
    },
    __metadata__: {
      icon: 'gs_fleet',
      isCustomizable: true,
      exportMethod: 'VehicleAdm/ExportVehiclesSimplified',
    },
  },
  positionsReportSmart: {
    name: 'positionsReportSmart',
    url: '/smart/relatorio/posicoes',
    template: '<golfleet-smart-report-positions></golfleet-smart-report-positions>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'positionsReportSmart',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  positionsSmartReportAdm: {
    name: 'positionsSmartReportAdm',
    url: '/smart/admin/relatorio/posicoes',
    template: '<golfleet-smart-report-positions></golfleet-smart-report-positions>',
    params: {},
    data: {
      routeName: '',
      routeLink: 'positionsSmartReportAdm',
      routeTail: null,
      stateConfig: null,
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  /*  */
};

const GetRouteConfig = routeName => RoutesConfig[routeName];

export { RoutesConfig, GetRouteConfig };
