/* global L: true isIframe: true */
import { PowerMapVehiclesLeafletProvider } from '@power/power-components/components/power-map-vehicles/providers/leaflet/power-map-vehicles.leaflet-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-smart-map-vehicles.leaflet-provider.scss';

class GolfleetSmartMapVehiclesLeafletProvider extends PowerMapVehiclesLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        __modules: behavior.session.modules,
      }),
    )(this);

    this.intervalFullscreen = null;
    this.msgInterval = null;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _geoJsonToMarker({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon: this._createMarkerIcon(feature.properties),
        }).bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.placaComApelido}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${feature.properties.ultimaPosicaoFormatado}</span>
            </div>
            <div>
              <b>Velocidade:</b>
              <br>
              <span>
                <i class="material-icons"
                  style="font-size: 11px;
                  color: ${!feature.properties.ignicao ? '#980A1A' : '#4AAE4E'}">
                  lens
                </i>
                ${feature.properties.velocidadeFormatada}
              </span>
            </div>
            ${
              isIframe()
                ? ''
                : `
                  <div style="margin-top:8px;text-align:center">
                    <a class="gs-link"
                      target="_blank"
                      href="${`https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}`}">
                      Ver no StreetView
                    </a>
                  </div>
                `
            }
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${latlng.lat} </span>
            <span> Lon: ${latlng.lng} </span>
          </div>
				`),
    }).getLayers()[0];
  }
  /* */

  /* Observers */
  /* */
}

export { GolfleetSmartMapVehiclesLeafletProvider };
