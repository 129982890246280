import angular from 'angular';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/directives/to-bytes/to-bytes';
import '@power/power-components/directives/trust-src/trust-src';

import template from './golfleet-file-list-options.html';
import './golfleet-file-list-options.scss';

class GolfleetFileListOptionsController {
  static get $inject() {
    return ['$element', '$scope', 'crudServices', 'reportServices'];
  }

  constructor($element, $scope, crudServices, reportServices) {
    Object.assign(this, { $: $element[0], $scope, crudServices, reportServices });

    this.itensList = [];

    this.actualFileIndex = 0;

    this.warning = null;

    this.itemOptions = [];

    this.newItemId = null;

    this.newNote = '';

    this.newFile = null;

    this.disabled = false;

    this.isLoading = false;
  }

  $onInit() {
    Object.assign(this.$, {
      getitensList: this.getitensList.bind(this),
      additensList: this.additensList.bind(this),
    });

    this._getDataSet();
  }

  _getDataSet() {
    this.crudServices.getData(this.getDataMethodItem, null).then(result => {
      result.forEach(r => {
        r.value = r.id;
      });
      this.itemOptions = result;
    });
  }

  _addItem() {
    if (this.newFile) {
      this.isLoading = true;
      this.reportServices
        .genericRequest(this.uploadPhotoMethod, {
          photoBase64: this.newFile.contentBase64,
          format: this.newFile.extension,
        })
        .then(
          success => {
            this._pushSelectedItem(success.data.data.key, success.data.data.url);
            this.$.querySelector('#file-input').value = '';
            this.isLoading = false;
          },
          () => {
            // error
          },
        );
    } else {
      this._pushSelectedItem(null, null);
    }
  }

  _pushSelectedItem(key, url) {
    this.itensList.push({
      checklistItemId: this.newItemId,
      itemDescription: this.itemOptions.find(i => i.selected == true).description,
      note: this.newNote,
      file: key,
      fileUrl: url,
    });
    this._clearSelectedItem();
  }

  _clearSelectedItem() {
    this.newItemId = undefined;
    this.newNote = null;
    this.newFile = null;
    this.itemOptions.forEach(option => {
      option.selected = false;
    });
  }

  _removeItem(index) {
    if (this.itensList.length <= this.maxFileList) {
      this.warning = null;
    }

    const popup = this.$.querySelector('#file-list-popup');

    if (this.itensList.length === 0 && popup.hasAttribute('open')) {
      popup.toggle();
    }

    if (this.actualFileIndex >= this.itensList.length - 1) {
      this.actualFileIndex -= 1;
    }

    this.itensList.splice(index, 1);
  }

  async _openDialog(index) {
    this.actualFileIndex = index;
    this.actualFile = {
      key: this.itensList[index].file,
      url: this.itensList[index].fileUrl,
    };

    if (this.actualFile.url) this.$.querySelector('#file-list-popup').toggle();
  }

  async _downloadFile(file) {
    const blob = await (await fetch(file.contentBase64)).blob();
    const fileUrl = window.URL.createObjectURL(blob, { type: file.type });
    const downloadLink = document.createElement('a');

    downloadLink.download = `${file.name}${file.extension}`;
    downloadLink.href = fileUrl;
    downloadLink.click();
  }

  _fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  _closePopUp() {
    if (this.$.querySelector('#file-list-popup').toggle) {
      this.$.querySelector('#file-list-popup').toggle();
    }
  }

  /* Public */
  getitensList() {
    return this.itensList;
  }

  additensList(file) {
    this.itensList = file;
  }
  /* */

  async __onInputFileChanged() {
    const [file] = this.$.querySelector('#file-input').files;

    if (file) {
      const extension = /\.\w+$/.exec(file.name);
      if (file.size > this.maxFileSize) {
        this.warning = `Tamanho máximo do arquivo: ${this.maxFileSize / 1048576} MB`;
      } else if (extension[0] !== '.png' && extension[0] !== '.jpg' && extension[0] !== '.jpeg') {
        this.warning = `Formato de arquivo inválido`;
      } else {
        this.warning = null;

        const contentBase64 = await this._fileToBase64(file);

        this.newFile = {
          type: file.type,
          name: file.name.substring(0, extension.index),
          size: file.size,
          extension: extension[0],
          contentBase64,
        };
      }

      if (!this.$scope.$$phase) {
        this.$scope.$apply();
      }
    }
  }

  _addFile() {
    this.$.querySelector('#file-input').click();
  }

  _removeFile() {
    this.newFile = null;
  }

  _canAddLine() {
    return this.newItemId && this.newNote;
  }
}

class GolfleetFileListOptions {
  constructor() {
    this.template = template;
    this.bindings = {
      accept: '=?',
      itensList: '=?',
      maxFileSize: '=?',
      maxFileList: '=?',
      itemOptions: '=?',
      getDataMethodItem: '=?',
      uploadPhotoMethod: '=?',
      disabled: '=?',
    };
    this.transclude = {};
    this.controller = GolfleetFileListOptionsController;
  }
}

class FileChangeLink {
  constructor($scope, element) {
    this.$scope = $scope;
    this.element = element;

    this.initFileInput();
  }

  initFileInput() {
    const inputElement = this.element[0];
    if (inputElement) {
      inputElement.onchange = event => {
        this.$scope.onFileChange({ $event: event });
        this.$scope.$apply();
      };
    }
  }
}

class FileChangeDirective {
  static get $$ngIsClass() {
    return true;
  }

  constructor() {
    this.restrict = 'A';
    this.scope = {
      onFileChange: '&',
    };
  }

  link($scope, element) {
    return new FileChangeLink($scope, element);
  }
}

angular
  .module('golfleet-file-list-options', [
    'trust-src',
    'to-bytes',
    'power-popup',
    'power-crud-combobox',
    'power-crud-textbox',
  ])
  .directive('fileChange', FileChangeDirective)
  .component('golfleetFileListOptions', new GolfleetFileListOptions());
